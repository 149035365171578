import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';
import { take } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LanguagesService
{

    private _localeMap = {
        'ua': 'uk',
        'en': 'en'
    }

    private _countryMap = {
        'ua': 'ua',
        'en': 'us'
    }

    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService) { }

    setNLS(language: string): void
    {
         this._userService.user$.pipe(take(1))
           .subscribe({ next: (user: User) => {
                this._httpClient.post(environment.apiBaseUrl + '/api/setNLS', { language, email: user.email }).subscribe();
            }});
    }

    getLocale(lang: string): string
    {
        return this._localeMap[lang];
    }

    getISOCountry(lang: string): string
    {
        return this._countryMap[lang];
    }

}
