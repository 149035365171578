import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { TranslocoModule } from '@ngneat/transloco';
import { UserService } from 'app/core/user/user.service';
import { ContactsPanelComponent } from 'app/modules/share/components/contacts-panel/contacts-panel.component';
import { EmployeeBriefly } from 'app/modules/share/global.types';
import { Observable, map } from 'rxjs';
import { ChatService } from '../chat.service';
import { Chat, ChatType } from '../chat.types';

export type DialogData = {
    chat: Chat;
    contacts: EmployeeBriefly[]
}

@Component({
    selector: 'app-chat-details-dialog',
    standalone: true,
    imports: [
        CommonModule, ContactsPanelComponent, FormsModule,
        MatFormFieldModule, MatInputModule, MatButtonModule, MatIconModule, MatChipsModule,
        MatDialogModule, MatSelectModule, TranslocoModule
     ],
    templateUrl: './chat-details-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatDetailsDialogComponent implements OnInit
{
    chat: Chat;
    chatTypes = [ChatType.group, ChatType.room];
    contacts$: Observable<EmployeeBriefly[]>;

    constructor(
        public dialogRef: MatDialogRef<ChatDetailsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private _chatService: ChatService,
        private _userService: UserService
    ) {
        this.chat = data.chat;
    }

    ngOnInit(): void
    {
        this.contacts$ = this._chatService.contacts$
            .pipe(map(contacts =>
                contacts.filter(itm => itm.login !== this._userService.login)));
    }

    onNoClick(): void
    {
        this.dialogRef.close();
    }

    onRecipientsSelected(event: EmployeeBriefly[])
    {
        this.chat.contacts = event;
    }

}
