import { Injectable } from '@angular/core';
import { WebSocketSubject, WebSocketSubjectConfig, webSocket } from 'rxjs/webSocket';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';
import { WsIncomeMessage } from '../global.types';

@Injectable({
  providedIn: 'root'
})
export class WsMessagesService {

    private _subject: WebSocketSubject<WsIncomeMessage>;

    get ws$(): Observable<WsIncomeMessage>
    {
        return this._subject.asObservable();
    }

    constructor(private _auth: AuthService) {
        this.connect();
        this.send({
            type: 'authenticate',
            token: this._auth.accessToken
        })
    }

    public connect() {
        this._subject = webSocket({
            url: `${ environment.wsUrl }?token=${ encodeURIComponent(this._auth.accessToken) }`,
            deserializer: msg => JSON.parse(msg.data),
            serializer: msg => JSON.stringify(msg),
            openObserver: {
                next: () => {
                    console.log('connexion ok');
                }
            },
            closeObserver: {
                next: () => {
                    console.log('disconnect ok');
                }
            }
        } as WebSocketSubjectConfig<any>);

        this._subject.subscribe(
            msg => {}, //console.log('ws message received: ', msg),
            err => console.log('ws error', err),
            () => console.log('ws complete')
        );

    }

    public send(message: any)
    {
        this._subject.next(message);
    }

    public disconnect()
    {
        this._subject.complete();
    }

}
