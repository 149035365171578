import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmployeeBriefly } from '../../global.types';
import { ContactPickerComponent } from '../picker/contact-picker/contact-picker.component';
import { EmployeesBrieflyService } from '../../services/employees-briefly.service';
import { Observable } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslocoModule } from '@ngneat/transloco';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';

@Component({
    selector: 'app-contacts-panel',
    standalone: true,
    imports: [
        CommonModule,
        TranslocoModule,

        MatIconModule,
        MatButtonModule,
        MatInputModule,
        MatFormFieldModule,
        MatChipsModule,

        ContactPickerComponent
    ],
    templateUrl: './contacts-panel.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactsPanelComponent
{
    @Input() labelText: string;
    @Input() contactsSelected: EmployeeBriefly[];
    @Input() contacts$: Observable<EmployeeBriefly[]>;
    @Input() panelClass: string;
    @Output() onSelect = new EventEmitter<EmployeeBriefly[]>();

    constructor(
        private _employees: EmployeesBrieflyService
    ) {
        !this.contacts$ &&
            (this.contacts$ = this._employees.employees$);
    }

    select(contacts: EmployeeBriefly[])
    {
        this.contactsSelected = contacts;
        this.onSelect.emit(contacts);
    }
}
