<ng-container *ngIf="!isMinimized; else minimizedDialog">
    <ng-container *ngIf="data.type === 'outgoing'">
        <div class="p-12 bg-slate-900 flex flex-col gap-6">
            <div class="text-on-primary text-lg flex justify-center gap-3">
                <div>
                    Дзвінок на {{ data.phoneNumber | phone }}
                </div>
                <button
                    (click)="setIsMinimize(true)"
                    class="icon-size-3"
                    mat-icon-button>
                    <mat-icon class="icon-size-3 text-on-primary" [svgIcon]="'heroicons_mini:arrows-pointing-in'"></mat-icon>
                </button>
            </div>
            <div class="flex justify-between gap-24">
                <div class="w-16 h-16 bg-green-600 rounded-full flex items-center justify-center">
                    <mat-icon class="text-on-primary" [svgIcon]="'heroicons_solid:phone'"></mat-icon>
                </div>
                <div class="w-16 h-16 bg-green-600 rounded-full flex items-center justify-center">
                    <div class="w-15 h-15 bg-white rounded-full flex items-center justify-center">
                        <mat-icon class="icon-size-16" svgIcon="heroicons_solid:user-circle"></mat-icon>
                    </div>
                </div>
            </div>
            <div
                class="text-on-primary text-xl flex justify-center">{{ 'telephoneCall.outgoingState.' + (callStatus | async) | transloco }}
            </div>

            <div *ngIf="(callStatus | async) === 'initialCallState'">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>

            <button *ngIf="(callStatus | async) !== 'initialCallState'" class="rounded-xl" mat-flat-button color="warn"
                    (click)="endCall()">
                <mat-icon svgIcon="heroicons_mini:phone-x-mark"></mat-icon>
                <span class="ml-2">{{ 'telephoneCall.hangup' | transloco }}</span>
            </button>
        </div>
    </ng-container>

    <ng-container *ngIf="data.type === 'incoming'">
        <div class="p-6 bg-slate-900 flex flex-col gap-6 relative">
            <div class="text-secondary text-lg flex justify-center gap-3">
                <div>
                    Дзвінок від {{ data.phoneNumber | phone }}. Надійшов на {{ userTelephonyIntegration.name}}
                </div>
                <button
                    (click)="setIsMinimize(true)"
                    class="icon-size-3"
                    mat-icon-button>
                    <mat-icon class="icon-size-3 text-on-primary" [svgIcon]="'heroicons_mini:arrows-pointing-in'"></mat-icon>
                </button>
            </div>

            <div class="flex gap-4">
                <div class="w-9 h-9 bg-white rounded-full flex items-center justify-center">
                    <mat-icon class="icon-size-10" svgIcon="heroicons_solid:user-circle"></mat-icon>
                </div>
                <div
                    class="text-on-primary text-xl flex justify-center items-center">{{ (contact | async)?.name || 'Невідомий контакт' }}
                </div>
            </div>

            <div class="p-3 bg-default flex flex-col gap-7 rounded-xl">
                <!-- Information-->
                <div class="flex flex-col gap-3">
                    <div class="text-primary">Справка</div>
                    <div class="text-secondary ">
                        <div class=" text-sm">
                            Дата останнього вхідного виклика від {{ data.phoneNumber | phone }}
                        </div>
                        <div class="text-xs">
                            {{(contact | async)?.lastCallDate || '-'}}
                        </div>
                    </div>
                    <div class="text-secondary ">
                        <div class=" text-sm">
                            Всього дзівінків
                        </div>
                        <div class="text-xs">
                            {{(contact | async)?.callCount || 0}}
                        </div>
                    </div>
                </div>

                <!-- Contracts-->
                <div class="flex flex-col gap-3">
                    <div class="text-primary">Замовлення</div>
                    <div class="flex justify-between">
                        <div class="text-secondary ">
                            <div class=" text-sm">
                                Кількість замовлень
                            </div>
                            <div class="text-xs">
                                {{(contact | async)?.transactionCount || 0}}
                            </div>
                        </div>
                        <div class="text-secondary ">
                            <div class=" text-sm">
                                Загальна сумма замовлень
                            </div>
                            <div class="text-xs">
                                {{(contact | async)?.transactionSum || 0}} грн.
                            </div>
                        </div>
                    </div>

                </div>

                <!-- Responsible-->
                <div class="flex flex-col gap-3">
                    <div class="text-primary">Відповідальний</div>
                    <div class="flex gap-3 items-center" *ngIf="(contact | async)?.manager; else noManager">
                        <ng-container *ngIf="(contact | async).manager?.avatar">
                            <img class="w-full h-full rounded-full object-cover"
                                [src]="(contact | async)?.manager?.avatar"
                                alt="Manager avatar"/>
                        </ng-container>
                        <ng-container *ngIf="!(contact | async)?.manager?.avatar">
                            <mat-icon class="icon-size-6" svgIcon="heroicons_solid:user-circle"></mat-icon>
                        </ng-container>

                        <div class="text-secondary ">
                            <div class=" text-sm">
                                {{(contact | async)?.manager.name || ''}}
                            </div>
                            <div class="text-xs">
                                {{(contact | async)?.manager.position || ''}}
                            </div>
                        </div>
                    </div>
                    <ng-template #noManager>
                        <div class="flex gap-3 items-center">
                            <div class="text-secondary ">
                                <div class=" text-sm">
                                    Менеджер не призначений
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>
            <ng-container *ngIf="telephonyIntegrationType !== IntegrationSubType.binotel">
                <div *ngIf="(callStatus | async) === 'initialCallState'">
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </div>
                <div  class="text-secondary text-lg flex justify-center">{{ 'telephoneCall.incomingState.' + (callStatus | async) | transloco }}</div>
                <div class="flex justify-between gap-6">
                    <button *ngIf="(callStatus | async) === 'initialCallState' || (callStatus | async) === 'establishingCallState'" class="rounded-xl bg-green-600 w-full"
                            mat-flat-button color="primary"
                            (click)="acceptCall()">
                        <mat-icon svgIcon="heroicons_mini:phone"></mat-icon>
                        <span class="ml-2">{{ 'telephoneCall.accept' | transloco }}</span>
                    </button>

                    <button class="rounded-xl w-full" mat-flat-button color="warn"
                            (click)="endCall()">
                        <mat-icon svgIcon="heroicons_mini:phone-x-mark"></mat-icon>
                        <span class="ml-2">{{ 'telephoneCall.hangup' | transloco }}</span>
                    </button>
                </div>
            </ng-container>

            <ng-container *ngIf="telephonyIntegrationType === IntegrationSubType.binotel">
                <div class="flex justify-between gap-6">
                    <button class="rounded-xl w-full" mat-flat-button color="warn"
                            (click)="endCall()">
                        <mat-icon svgIcon="heroicons_mini:phone-x-mark"></mat-icon>
                        <span class="ml-2">{{ 'close' | transloco }}</span>
                    </button>
                </div>
            </ng-container>

        </div>
    </ng-container>
</ng-container>
<ng-template #minimizedDialog>
    <div class="p-6 bg-slate-900 flex gap-3 minimized">
        <div class="text-secondary text-lg flex justify-center">
            Дзвінок від {{ (contact | async)?.name || (data.phoneNumber | phone)  || 'Невідомий контакт'}}
        </div>
        <button
            (click)="setIsMinimize(false)"
            class="icon-size-3"
            mat-icon-button>
            <mat-icon class="icon-size-3 text-on-primary" [svgIcon]="'heroicons_mini:arrows-pointing-out'"></mat-icon>
        </button>
    </div>
</ng-template>
