import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslocoModule } from '@ngneat/transloco';
import { IntegrationsService } from '../../integrations.service';
import { IntegrationSubType } from '../../integrations.types';
import { BaseMessengerDialogComponent } from '../base-messenger-dialog/base-messenger-dialog.component';
import { DialogTemplateComponent } from '../dialog-template/dialog-template.component';

@Component({
    selector: 'app-telegram-dialog',
    standalone: true,
    imports: [
        CommonModule, TranslocoModule, DialogTemplateComponent, FormsModule, ReactiveFormsModule,
        MatFormFieldModule, MatInputModule, MatIconModule,
    ],
    templateUrl: './telegram-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TelegramDialogComponent extends BaseMessengerDialogComponent<TelegramDialogComponent>
{
    get integrationSubType(): IntegrationSubType
    {
        return IntegrationSubType.telegram;
    }

    constructor(
        private formBuilder: UntypedFormBuilder,
        private __dialogRef: MatDialogRef<TelegramDialogComponent>,
        private __integrations: IntegrationsService,
        @Inject(MAT_DIALOG_DATA) public data: any
    )
    {
        super(__dialogRef, __integrations, data);

        this.form = this.formBuilder.group({
            name      : [data.name, Validators.required, this.nameUniqValidator.bind(this)],
            apiKey    : [data.apiKey, Validators.required],
        });

    }

}
