import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatStepperModule } from '@angular/material/stepper';
import { RouterLink } from '@angular/router';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { Observable, debounceTime, map } from 'rxjs';
import { OracleResponse, OracleStatusCode } from 'app/modules/share/global.types';
import { CompanyInitData } from '../company-init.types';
import { CompanyInitService } from '../company-init.service';
import { REGEX_PASSWORD_PATTERN } from 'app/modules/share/global.constants';

@Component({
    selector: 'company-init',
    templateUrl: './register.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    providers: [
        {
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: {showError: true},
         },
    ],
    imports: [
        CommonModule,
        TranslocoModule,
        RouterLink,
        FormsModule,
        ReactiveFormsModule,

        MatStepperModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
    ],
})

export class RegisterComponent implements OnInit {

    companyInitData: CompanyInitData;
    firstFormGroup: UntypedFormGroup;
    secondFormGroup: UntypedFormGroup;

    constructor(
        private _formBuilder: FormBuilder,
        private _companyInitService: CompanyInitService,
        private _translocoService: TranslocoService,
    ) {}

    get first()
    {
        return this.firstFormGroup.controls;
    }

    get second()
    {
        return this.secondFormGroup.controls;
    }

    ngOnInit()
    {
        this.firstFormGroup = this._formBuilder.group(
         {
            email: ['',
                [
                    Validators.required,
                    Validators.email
                ],
                this.emailValidator.bind(this)
            ],
            password: ['', [
                Validators.required,
                Validators.pattern(REGEX_PASSWORD_PATTERN)
            ]]
        });

        this.secondFormGroup = this._formBuilder.group(
        {
            companyName: ['', Validators.required],
            companyDomain: ['',
                [
                    Validators.required,
                    this.subDomainValidator
                ]
            ],
        });
    }

    createCompany()
    {
        const companyInitData = {
            ...this.firstFormGroup.value,
            ...this.secondFormGroup.value,
            language: this._translocoService.getActiveLang()
        };


        this._companyInitService.registerCompany(companyInitData)
            .subscribe({ next: response => {
                if (response.StatusCode === OracleStatusCode.Success) {
                    this.companyInitData = companyInitData;
                }
            }}
        );
    }

    emailValidator (control: AbstractControl): Observable<ValidationErrors>
    {
        return this._companyInitService.checkUserEmail(control.value)
            .pipe(
                debounceTime(1000),
                map((response: OracleResponse) => {
                    return response.StatusCode === OracleStatusCode.Error
                    ? { usernameAlreadyExists: true } : null
                }
            )
        );
    }

    subDomainValidator (control: AbstractControl): ValidationErrors
    {
        const invalid =
            control.value.length > 63
            || control.value.startsWith('-')
            || control.value.endsWith('-')
            || !/^[a-zA-Z0-9-]*$/.test(control.value)
            ;
        return invalid ? { invalidDomainName: true } : null;
    }

}
