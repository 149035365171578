import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from 'app/core/user/user.types';
import {map, Observable, of, ReplaySubject, switchMap, tap} from 'rxjs';
import {AuthService} from "../../../core/auth/auth.service";

@Injectable({providedIn: 'root'})
export class user
{

    user: Observable<any>;
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient,  private _authService: AuthService)
    {
        //this.user = this._authService.getUsr();


    }
}



