import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';
import { of } from 'rxjs';

export const TokenGuard: CanActivateFn | CanActivateChildFn = (route, state) =>
{
    const authService = inject(AuthService);
    const router: Router = inject(Router);

    const token = route.queryParams['token'];
    //authService.accessToken = token;
    //authService.signInUsingToken();
    router.navigate(['']);

    return of(false);

};
