import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { FormBuilder, FormsModule, NgForm, ReactiveFormsModule, UntypedFormGroup, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { RouterLink } from '@angular/router';
import { OracleResponse, OracleStatusCode } from 'app/modules/share/global.types';
import { AuthService } from 'app/core/auth/auth.service';
import { FuseAlertComponent, FuseAlertType } from '@fuse/components/alert';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { NgIf } from '@angular/common';
import { LoginResponse } from 'app/core/auth/auth.types';
import { environment } from '../../../../../environments/environment';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-login',
    templateUrl: 'login.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        MatButtonModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        TranslocoModule,
        RouterLink,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        FuseAlertComponent,
        MatIconModule,
        NgIf
    ],
})

export class LoginComponent implements OnInit {
    @ViewChild('signInNgForm') signInNgForm: NgForm;

    alert: { type: FuseAlertType; message: string } = {
        type   : 'success',
        message: '',
    };
    signInForm: UntypedFormGroup;
    showAlert: boolean = false;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
      private _formBuilder: FormBuilder,
      private _authService: AuthService,
    ) { }

    ngOnInit()
    {
      this.signInForm = this._formBuilder.group({
        email: ['', [
          Validators.required,
          Validators.email
        ]],
        password: ['', Validators.required ],
        rememberMe: ['']
      });
    }

    /**
    * On destroy
    */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    login()
    {
        // Return if the form is invalid
        if ( this.signInForm.invalid )
        {
            return;
        }

        // Disable the form
        this.signInForm.disable();

        // Hide the alert
        this.showAlert = false;

        // Sign in
        this._authService.signIn(this.signInForm.value)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({next: (response: LoginResponse) =>
            {
                console.log(response);
                if (response.StatusCode === OracleStatusCode.Success)
                {
                    const token = response.data.accessToken;
                    window.location.href = environment.redirectUrl + '?token=' + token;
                }
                else if (response.StatusCode === OracleStatusCode.Error)
                {
                    this.errorHAndler(response);
                }
            }});

    }

    errorHAndler(response: OracleResponse) {
        console.log(response);

        // Re-enable the form
        this.signInForm.enable();

        // Reset the form
        this.signInNgForm.resetForm();

        // Set the alert
         this.alert = {
            type: 'error',
            message: response.Status,
        };

        // Show the alert
        this.showAlert = true;
    }
}
