import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CalendarService } from 'app/modules/admin/dashboards/project/calendar.service';
import { ProjectEvent } from 'app/modules/admin/dashboards/project/project.types';
import { DateTime } from 'luxon';
import { BehaviorSubject, Observable, Subscription, combineLatest, filter, firstValueFrom, interval, map, tap } from 'rxjs';
import { EventRemindDialogComponent } from '../components/dialogs/event-remind-dialog/event-remind-dialog.component';
import { SECOND } from '../global.constants';

@Injectable({
    providedIn: 'root'
})
export class CalendarReminderService {

    intervalSubscription: Subscription;
    timerSubscription: Subscription;

    readonly POSTPONE_MINUTES = 10;
    private _events$ = new BehaviorSubject<ProjectEvent[]>(null);
    private readonly REMINDER_MINUTES = 15;
    private readonly NOT_POSTPONE_MINUTES = 5;
    private readonly INTERVAL_SECONDS = 30;
    private _onPause = false;

    constructor(
        private _calendarService: CalendarService,
        public _dialog: MatDialog,
    )
    {
    }

    async init()
    {
        if(!this.intervalSubscription)
        {
            this.intervalSubscription = combineLatest([
                interval(SECOND * this.INTERVAL_SECONDS),
                this._events$
            ])
            .pipe(
                filter(([interval, events]) => !this._onPause && !!events))
            .subscribe(([interval, events]) =>
            {
                const nowDate = new Date();
                events.forEach((event) =>
                {
                    const canProcessEvent = !!event.remindTime && !event.opened
                        && nowDate.getTime() >= event.remindTime.getTime()
                        && event.start.getTime() > nowDate.getTime();

                    canProcessEvent && this._processEvent(event);
                });
            });
        }

        this._calendarService.eventUpdated$
            .subscribe(async () => {
                this._onPause = true;
                await firstValueFrom(this.getTodayEvents())
                this._onPause = false;
            });

        await firstValueFrom(this.getTodayEvents());
/*         if(!this.timerSubscription)
        {
            this.timerSubscription = timer(0, SECOND * 60).subscribe(async () =>
                await firstValueFrom(this.getTodayEvents()));
        }
 */
    }

    getTodayEvents(): Observable<any>
    {
        const date = new Date();
        return this._calendarService.getCalendarEvent(date, date)
            .pipe(
                map((events: any[]) => events.map(event => {
                    const startDateTime = this._calendarService.toDateTime(event.startDate, event.startTime);
                    return {
                        title: event.description,
                        start: startDateTime.toJSDate(),
                        remindTime: startDateTime.minus({ minutes: this.REMINDER_MINUTES }).toJSDate()
                    }
                })),
                tap(events => this._events$.next([...events]))
            )
    }

    private _processEvent(event: ProjectEvent)
    {
        event.opened = true;
        const dialogRef = this._dialog.open(EventRemindDialogComponent, { data: event });
        dialogRef.afterClosed().subscribe(result =>
        {
            if(event.postponed)
            {
                event.remindTime = null;
            }
            else
            {
                this._postponeEvent(event, result);
            }
            event.opened = false;
        });

        setTimeout(() => dialogRef.close(), SECOND * 60);
    }

    private _postponeEvent(event: ProjectEvent, status: 'postpone' | '' = '')
    {
        event.postponed = true;
        event.remindTime = DateTime.fromJSDate(event.remindTime)
            .plus({
                minutes: status === 'postpone' ? this.POSTPONE_MINUTES : this.NOT_POSTPONE_MINUTES
            }).toJSDate();

        //console.log('_postponeEvent', event);

    }
}
