import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { TranslocoModule } from '@ngneat/transloco';
import { CalendarReminderService } from 'app/modules/share/services/calendar-reminder.service';
import { DateTime } from 'luxon';

@Component({
    selector: 'app-event-remind-dialog',
    standalone: true,
    imports: [
        CommonModule,
        TranslocoModule,

        MatButtonModule,
        MatDialogModule,

    ],
    templateUrl: './event-remind-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventRemindDialogComponent {

    postponeTime: number;
    difference: number;

    constructor(
        private _calendarService: CalendarReminderService,
        public dialogRef: MatDialogRef<EventRemindDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    )
    {
        //const dateTime = DateTime;
        this.postponeTime = this._calendarService.POSTPONE_MINUTES;
        this.difference = Math.floor(DateTime.fromJSDate(this.data.start).diffNow('minutes').minutes);
    }

    onCancelClick(): void
    {
        this.dialogRef.close();
    }
}
