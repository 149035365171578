<div class="w-full">

    <h1 class="font-medium text-xl py-4">
        {{ 'companyInit.title' | transloco }}
    </h1>
    <p class="my-4">
        {{ 'companyInit.text' | transloco}}
    </p>

    <ng-container
        *ngIf="!!companyInitData; then created; else init">
    </ng-container>

    <ng-template #created>
        <div class="mat-horizontal-content-container">
            <div class="my-4 font-medium">
                {{ 'companyInit.companyCrated' |  transloco : { companyName: companyInitData.companyName } }}
            </div>
            <div class="font-medium my-2">
                {{ 'companyInit.email' | transloco }}
            </div>
            <div class="italic">
                {{ companyInitData.email }}
            </div>
            <div class="font-medium my-2">
                {{ 'companyInit.subdomainName' | transloco }}
            </div>
            <div class="italic">
                {{ companyInitData.companyDomain }}
            </div>
        </div>
    </ng-template>
    <ng-template #init>

        <mat-stepper #stepper linear>
            <mat-step [stepControl]="firstFormGroup" label="{{ 'companyInit.step1' | transloco }}">
                <form [formGroup]="firstFormGroup" class="p-4">
                    <mat-form-field class="w-full mb-4">
                        <mat-label>
                            {{ 'companyInit.email' | transloco }}
                        </mat-label>
                        <input matInput formControlName="email" type="email">
                        <mat-error *ngIf="first.email.hasError('required')">
                            {{ 'companyInit.emailError' | transloco }}
                        </mat-error>
                        <mat-error *ngIf="first.email.hasError('usernameAlreadyExists')">
                            {{ 'companyInit.emailExistsError' | transloco }}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="w-full mb-4">
                        <mat-label>
                            {{ 'password' | transloco }}
                        </mat-label>
                        <input matInput formControlName="password" type="password" #passwordField>
                        <button
                            mat-icon-button
                            type="button"
                            (click)="passwordField.type === 'password' ? passwordField.type = 'text' : passwordField.type = 'password'"
                            matSuffix>
                            <mat-icon
                                class="icon-size-5"
                                *ngIf="passwordField.type === 'password'"
                                [svgIcon]="'heroicons_solid:eye'"></mat-icon>
                            <mat-icon
                                class="icon-size-5"
                                *ngIf="passwordField.type === 'text'"
                                [svgIcon]="'heroicons_solid:eye-slash'"></mat-icon>
                        </button>
                        <mat-error *ngIf="first.password.invalid">
                            {{ 'error.invalidPassword' | transloco }}
                        </mat-error>
                    </mat-form-field>

                    <div class="text-right">
                        <button mat-raised-button color="primary" matStepperNext>
                            {{ 'companyInit.next' | transloco }}
                        </button>
                    </div>
                </form>
            </mat-step>
            <mat-step [stepControl]="secondFormGroup" label="{{ 'companyInit.step2' | transloco }}">
                <form [formGroup]="secondFormGroup" class="p-4 mb-4">
                    <mat-form-field class="w-full mb-4">
                        <mat-label>
                            {{ 'companyInit.companyName' | transloco }}
                        </mat-label>
                        <input matInput formControlName="companyName">
                        <mat-error *ngIf="second.companyName.invalid">
                            {{ 'companyInit.companyNameError' | transloco }}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="w-full mb-4">
                        <mat-label>
                            {{ 'companyInit.subdomainName' | transloco }}
                        </mat-label>
                        <input matInput formControlName="companyDomain">
                        <mat-error *ngIf="second.companyDomain.invalid">
                            {{ 'companyInit.subdomainNameError' | transloco }}
                        </mat-error>
                    </mat-form-field>
                    <div class="text-right">
                        <button mat-raised-button matStepperPrevious>
                            {{ 'companyInit.back' | transloco }}
                        </button>
                        <button class="ml-2" mat-raised-button color="primary" matStepperNext>
                            {{ 'companyInit.next' | transloco }}
                        </button>
                    </div>
                </form>
            </mat-step>
            <mat-step label="{{ 'companyInit.step3' | transloco }}" class="p-4">
                <div class="p-4">
                    <p class="font-medium text-base py-4">{{ 'companyInit.step3Message' | transloco }}</p>

                    <div class="font-medium my-2">
                        {{ 'companyInit.email' | transloco }}&colon;
                    </div>
                    <div class="italic">
                        {{ first.email.value }}
                    </div>
                    <div class="font-medium my-2">
                        {{ 'password' | transloco }}&colon;
                    </div>
                    <div class="italic">
                        {{ first.password.value }}
                    </div>
                    <div class="font-medium my-2">
                        {{ 'companyInit.companyName' | transloco }}&colon;
                    </div>
                    <div class="italic">
                        {{ second.companyName.value }}
                    </div>
                    <div class="font-medium my-2">
                        {{ 'companyInit.subdomainName' | transloco }}&colon;
                    </div>
                    <div class="italic">
                        {{ second.companyDomain.value }}
                    </div>

                    <div class="mt-4 text-right">
                        <button mat-raised-button matStepperPrevious>
                            {{ 'companyInit.back' | transloco }}
                        </button>
                        <button class="ml-2" mat-raised-button (click)="stepper.reset()">
                            {{ 'companyInit.reset' | transloco }}
                        </button>
                    </div>
                    <div class="text-center">
                        <button class="mt-8 w-1/2 mat-raised-button" mat-flat-button [color]="'primary'" (click)="createCompany()">
                            {{ 'companyInit.create' | transloco }}
                        </button>
                    </div>
                </div>
            </mat-step>
        </mat-stepper>
    </ng-template>

    <div class="text-center">
        <span>
            {{ 'auth.alreadyRegistered' | transloco }}&quest;&nbsp;
            <a class="text-primary-500 hover:underline mat-raised-button" [routerLink]="['/sign-in']">
                {{ 'auth.signIn' | transloco }}
            </a>
        </span>
    </div>
</div>
