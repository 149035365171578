import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { FlexibleField } from '../global.types';

@Injectable({
    providedIn: 'root'
})
export class FlexibleFieldsService {
    private _filters: BehaviorSubject<FlexibleField[]> = new BehaviorSubject(null);

    get filters$(): Observable<FlexibleField[]>
    {
        return this._filters.asObservable();
    }

    get filters(): FlexibleField[]
    {
        return this._filters.getValue();
    }

    constructor(private _http: HttpClient) { }

    getFilters()
    {
        return this._http.get<FlexibleField[]>(environment.apiBaseUrl + '/api/getcustfilterlist')
            .pipe(tap(
                filters => this._filters.next(filters)
            ));
    }
}
