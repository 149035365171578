import { NgClass, NgIf } from '@angular/common';
import { Component, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { FuseLoadingBarComponent } from '@fuse/components/loading-bar';
import { TranslocoModule } from '@ngneat/transloco';
import { LanguagesComponent } from 'app/layout/common/languages/languages.component';
import { LogoImageResolveDirective } from 'app/modules/share/directive/logo-image-resolve.directive';
import { Subject } from 'rxjs';

@Component({
    selector     : 'auth-layout',
    templateUrl  : './auth.component.html',
    styleUrls    : ['./auth.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone   : true,
    imports      : [
        NgIf,
        NgClass,
        RouterOutlet,
        TranslocoModule,

        FuseLoadingBarComponent,
        LanguagesComponent,

        LogoImageResolveDirective,
    ],
})
export class AuthLayoutComponent implements OnDestroy
{
    @Input() fullSize = false;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor()
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
