import { AmioDialogComponent } from "./dialogs/amio-dialog/amio-dialog.component";
import { AsteriskDialogComponent } from "./dialogs/asterisk-dialog/asterisk-dialog.component";
import { AwsDialogComponent } from "./dialogs/aws-dialog/aws-dialog.component";
import { BinotelDialogComponent } from "./dialogs/binotel-dialog/binotel-dialog.component";
import { GmailDialogComponent } from "./dialogs/gmail-dialog/gmail-dialog.component";
import { EmailDialogComponent } from "./dialogs/mail-dialog/email-dialog.component";
import { TelegramDialogComponent } from "./dialogs/telegram-dialog/telegram-dialog.component";
import { ViberDialogComponent } from "./dialogs/viber-dialog/viber-dialog.component";
import { IntegrationSubType, IntegrationType, IntegrationsTypeMap } from "./integrations.types";

export const integrations: IntegrationsTypeMap =
{
    [IntegrationSubType.telegram]: {
        component: TelegramDialogComponent,
        integrationType: IntegrationType.messengers,
        integrationSubType: IntegrationSubType.telegram,
    },
    [IntegrationSubType.viber]: {
        component: ViberDialogComponent,
        integrationType: IntegrationType.messengers,
        integrationSubType: IntegrationSubType.viber,
    },
    [IntegrationSubType.gmail]: {
        component: GmailDialogComponent,
        integrationType: IntegrationType.email,
        integrationSubType: IntegrationSubType.gmail,
    },
    [IntegrationSubType.amio]: {
        component: AmioDialogComponent,
        integrationType: IntegrationType.marketing,
        integrationSubType: IntegrationSubType.amio,
    },
    [IntegrationSubType.email]: {
        component: EmailDialogComponent,
        integrationType: IntegrationType.marketing,
        integrationSubType: IntegrationSubType.email,
    },
    [IntegrationSubType.aws]: {
        component: AwsDialogComponent,
        integrationType: IntegrationType.marketing,
        integrationSubType: IntegrationSubType.aws,
    },
    [IntegrationSubType.asterisk]: {
        component: AsteriskDialogComponent,
        integrationType: IntegrationType.telephony,
        integrationSubType: IntegrationSubType.asterisk,
    },
    [IntegrationSubType.binotel]: {
        component: BinotelDialogComponent,
        integrationType: IntegrationType.telephony,
        integrationSubType: IntegrationSubType.binotel,
    },
}
