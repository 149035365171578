<ng-template #contactsPanel>

    <div class="rounded border shadow-md bg-card">
        <div class="flex items-center m-3 mr-2">
            <div class="flex items-center">
                <mat-icon
                    class="icon-size-5"
                    [svgIcon]="'heroicons_solid:magnifying-glass'"></mat-icon>
                <div class="ml-2">
                    <input matInput
                        [(ngModel)]="filterText"
                        class="w-full min-w-0 py-1 border-0"
                        type="text"
                        [placeholder]="'enterContactName' | transloco"
                        [maxLength]="80"
                        #newAssigneeInput>
                </div>
            </div>
        </div>
        <div class="flex flex-col max-h-64 py-2 border-t overflow-y-auto">
            <ng-container *ngFor="let contact of contactsList; trackBy: trackByFn">
                <div matRipple
                    (click)="onChange(contact)"
                    class="flex items-center h-10 min-h-10 px-2 gap-y-2 cursor-pointer hover:bg-hover">
                    <img [src]="contact.avatar" class="w-8 h-8 ml-2 rounded-full"/>
                    <mat-checkbox
                        *ngIf="multiselect"
                        class="flex items-center h-10 min-h-10 pointer-events-none"
                        [checked]="contact.checked"
                        [color]="'primary'"
                        [disableRipple]="true">
                    </mat-checkbox>
                    <div [ngClass]="{ 'ml-2': !multiselect }">
                        {{ contact.fullName }}
                    </div>
                </div>
            </ng-container>
        </div>
    </div>

</ng-template>
