<app-dialog-template
    class="h-full"
    [canSave]="form.valid"
    [dialogType]="integrationSubType"
    [data]="data"
    (save)="save($event)"
>
    <form [formGroup]="form">
        <div class="grid mx-0 sm:grid-cols-2 gap-4 w-full mt-4">
            <div class="sm:col-span-1">
                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                    <mat-label>{{ 'name' | transloco }}</mat-label>
                    <mat-icon matPrefix class="icon-size-5" svgIcon="heroicons_solid:user-circle"></mat-icon>
                    <input matInput formControlName="name">
                    <mat-error *ngIf="f.name.hasError('required')">
                        {{ 'requiredFieldError' | transloco }}
                    </mat-error>
                    <mat-error *ngIf="f.name.hasError('nameIsNotUniq')">
                        {{ 'integrations.nameUniqError' | transloco }}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="sm:col-span-1">
                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                    <mat-label>{{ 'integrations.login' | transloco }}</mat-label>
                    <mat-icon matPrefix class="icon-size-5" svgIcon="heroicons_solid:user-circle"></mat-icon>
                    <input type="login" matInput formControlName="login">
                    <mat-error *ngIf="f.login.hasError('required')">
                        {{ 'requiredFieldError' | transloco }}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="grid mx-0 sm:grid-cols-2 gap-4 w-full mt-4">
            <div class="sm:col-span-1">
                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                    <mat-label>{{ 'password' | transloco }}</mat-label>
                    <mat-icon matPrefix class="icon-size-5" svgIcon="heroicons_solid:lock-closed"></mat-icon>
                    <input type="password" matInput formControlName="password">
                </mat-form-field>
                <mat-error *ngIf="f.password.hasError('required')">
                    {{ 'requiredFieldError' | transloco }}
                </mat-error>
            </div>
            <div class="sm:col-span-1">
                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                    <mat-label>{{ 'integrations.host' | transloco }}</mat-label>
                    <mat-icon matPrefix class="icon-size-5" svgIcon="heroicons_solid:server"></mat-icon>
                    <input matInput formControlName="host">
                </mat-form-field>
                <mat-error *ngIf="f.host.hasError('required')">
                    {{ 'requiredFieldError' | transloco }}
                </mat-error>
            </div>
        </div>
        <div class="grid mx-0 sm:grid-cols-1 gap-4 w-full mt-4">
            <div class="sm:col-span-1">
                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                    <mat-label>{{ 'integrations.port' | transloco }}</mat-label>
                    <mat-icon matPrefix class="icon-size-5" svgIcon="heroicons_solid:server-stack"></mat-icon>
                    <input type="number" matInput formControlName="port">
                </mat-form-field>
                <mat-error *ngIf="f.port.hasError('required')">
                    {{ 'requiredFieldError' | transloco }}
                </mat-error>
            </div>
        </div>
    </form>

</app-dialog-template>
