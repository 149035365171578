import { ComponentType } from "@angular/cdk/portal";
import { EmployeeBriefly } from "app/modules/share/global.types";

export type DialogDataInput = {
    logoSrc: string;
    title: string;
    titleSecondary: string;
    image1Src: string;
    image2Src: string;
    image3Src: string;
    text1?: string;
    text2?: string;
    liText1?: string;
    liText2?: string;
    liText3?: string;
    liText4?: string;
    text3?: string;
    text4?: string;
}

export enum IntegrationType {
    messengers = 'MESSENGERS',
    email = 'EMAIL',
    marketing = 'MARKETING',
    telephony = 'IP'
}

export enum IntegrationSubType {
    telegram = 'telegram',
    viber = 'viber',
    gmail = 'gmail',
    amio = 'amio',
    asterisk = 'asterisk',
    binotel = 'binotel',
    email = 'email',
    aws = 'aws'
}

export enum MessengerType
{
    viber = 'viber',
    telegram = 'telegram',
    watsUp = 'watsUp',
    mobile = 'mobile',
    amioChat = 'amioChat',
    facebook = 'facebook',
    rcs = 'rcs'
}

export type SenderDataType = Map<string, any>

export type Integration = {
    isNew?: boolean,
    id?: string,
    name?: string,
    integrationType?: IntegrationType,
    integrationSubType?: IntegrationSubType,
    messengerType?: MessengerType | 'email',
    login?: string,
    password?: string,
    port?: string,
    sshPort?: string,
    sshPassword?: string,
    token?: string,
    host?: string,
    channel?: string,
    access?: number[] | EmployeeBriefly[],
    internalNumber?: string
    autoAnswer?: string;
    createTask?: boolean;
    userName?: string;
}

export type IntegrationsTypeMap = {
    [key: string] : {
        component: ComponentType<any>,
        integrationType: IntegrationType,
        integrationSubType: IntegrationSubType,
    }
}

export type ResponseQueueItem = {
    seqQueue?: number;
    userId?: string;
    interval?: number;
}
