import { CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslocoModule } from '@ngneat/transloco';
import { EmployeeBriefly } from 'app/modules/share/global.types';
import { EmployeesBrieflyService } from 'app/modules/share/services/employees-briefly.service';
import { Observable } from 'rxjs';
import { ResponseQueueItem } from '../../integrations.types';

@Component({
    selector: 'app-response-queue',
    standalone: true,
    imports: [
        CommonModule,
        TranslocoModule,
        FormsModule,
        ReactiveFormsModule,
        CdkDropList,
        CdkDrag,

        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatButtonModule,
        MatIconModule,
        MatSlideToggleModule,
    ],
    templateUrl: './response-queue.component.html',
    styleUrls: ['response-queue.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResponseQueueComponent implements OnInit
{
    @Input() data: any;
    @Input() queueForm: FormGroup<{queue: FormArray}>;
    managers$: Observable<EmployeeBriefly[]>

    get f()
    {
        return this.queueForm?.controls;
    }

    get queueFormArray()
    {
        return this.f.queue;
    }

    constructor(
        private _employeesBriefly: EmployeesBrieflyService,
        private _changeDetector: ChangeDetectorRef,
    )
    {
        this.managers$ = this._employeesBriefly.employees$;
    }

    ngOnInit(): void
    {
        this.queueForm.patchValue(this.data);
        this.data.queue?.forEach(queueItem => this.add(queueItem));
    }

    add(queueItem: ResponseQueueItem = {})
    {
        this.queueFormArray.push(
            new FormGroup({
                seqQueue: new FormControl(this.queueFormArray.length + 1),
                userId: new FormControl(queueItem?.userId || '', Validators.required),
                interval: new FormControl(queueItem?.interval || 1, Validators.required)
            })
        );
    }

    remove(idx: number)
    {
        this.queueFormArray.removeAt(idx);
    }

    drop(event: CdkDragDrop<string[]>)
    {
        moveItemInArray(this.queueFormArray.controls, event.previousIndex, event.currentIndex);
        this.queueFormArray.controls.forEach((control, idx) => control.patchValue({
            seqQueue: idx + 1
        }));
        this._changeDetector.markForCheck();
    }

    compareManagers(a: number, b: number)
    {
        return String(a) === String(b);
    }

}
