import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CompanyInitData } from "./company-init.types";
import { OracleResponse } from "app/modules/share/global.types";
import { HttpClientService } from "../share/services/http-client.service";

@Injectable({providedIn: 'root'})
export class CompanyInitService {

    constructor (private _http: HttpClientService) {}

    checkUserEmail (email: string) : Observable<any>
    {
        return this._http.get('/api/checkUser', { email });
    }

    registerCompany (companyInitData: CompanyInitData) : Observable<any>
    {
        return this._http.post<OracleResponse>('/api/AddCompany', companyInitData);
    }
}
