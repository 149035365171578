import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError, tap, throwError } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { OracleResponse, OracleStatusCode } from '../global.types';
import { HelperService } from './helper.service';

@Injectable({
    providedIn: 'root'
})
export class HttpClientService {

    constructor(
        private _http: HttpClient,
        private _helper: HelperService,
        private _router: Router,
    ) { }

    get<T>(api: string, params?: any, options = {}): Observable<T>
    {
        return this._http.get<T>(environment.apiBaseUrl + api, { ...options, params })
            .pipe(
                catchError((error: HttpErrorResponse) =>
                    this._errorHandler(error)),
                tap((response: T) => this._successHandler(response as OracleResponse))
            );
    }

    post<T>(api: string, payload?: any, params?: any): Observable<T>
    {
        return this._http.post<T>(environment.apiBaseUrl + api, payload, { params })
            .pipe(
                catchError((error: HttpErrorResponse) =>
                    this._errorHandler(error)),
                    tap((response: T) => this._successHandler(response as OracleResponse))
            );
    }

    patch<T>(api: string, payload?: any, params?: any): Observable<T>
    {
        return this._http.patch<T>(environment.apiBaseUrl + api, payload, { params })
            .pipe(
                catchError((error: HttpErrorResponse) =>
                    this._errorHandler(error)),
                tap((response: T) => this._successHandler(response as OracleResponse))
            );
    }

    private _errorHandler(error: HttpErrorResponse)
    {
        console.error(error);
        const errorMessage = typeof error === 'string' ? error : error.statusText;
        this._helper.showErrorMessage(errorMessage);

        this._router.navigate(['/pages/error/500']);

        return throwError(() => new Error(error.message));
    }

    private _successHandler(response: OracleResponse)
    {
        if(!!response.StatusCode && Number(response.StatusCode) !== OracleStatusCode.Success)
        {
            this._helper.showErrorMessage(response.Status);
        }
    }
}
