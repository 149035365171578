import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormArray, FormControl, FormGroup, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EmployeeBriefly } from 'app/modules/share/global.types';
import { IntegrationsService } from '../../integrations.service';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';

@Component({
    selector: 'app-base-messenger-dialog',
    standalone: true,
    imports: [],
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export abstract class BaseMessengerDialogComponent<T> extends BaseDialogComponent<T>
{
    queueForm: UntypedFormGroup;

    constructor(
        private dialogRef: MatDialogRef<T>,
        private integrations: IntegrationsService,
        @Inject(MAT_DIALOG_DATA) public data: any,
    )
    {
        super(dialogRef, integrations, data);

        this.queueForm = new FormGroup({
            createTask: new FormControl(),
            autoAnswer: new FormControl(),
            queue: new FormArray([])
        });
    }

    save(access?: EmployeeBriefly[])
    {
        const integrationType = this.data.isNew
            ? this.integrations.getIntegrationType(this.integrationSubType)
            : this.data.integrationType;

        this.dialogRef.close(
        {
            ...this.data,
            ...this.form.value,
            ...this.queueForm.value,
            integrationType,
            integrationSubType: this.integrationSubType,
            access,
        });
    }

/*     private _getQueue(queueFormValue: any)
    {
        return {
            autoAnswer: queueFormValue.autoAnswer,
            createTask: queueFormValue.createTask,
            queue: queueFormValue.map((val, idx) => ({
                seqQueue: idx + 1,
                userId: val.manager,
                interval: val.interval
            }))
        }
    } */
}
