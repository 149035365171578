import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IntegrationsService } from '../../integrations.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, UntypedFormArray, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { Integration, IntegrationSubType } from '../../integrations.types';
import { Observable, debounceTime, map, of } from 'rxjs';
import { EmployeeBriefly } from 'app/modules/share/global.types';
import { values } from 'lodash';

@Component({
    selector: 'app-base-dialog',
    standalone: true,
    imports: [CommonModule],
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export abstract class BaseDialogComponent<T> {

    form: UntypedFormGroup;

    get f()
    {
        return this.form.controls;
    }

    abstract get integrationSubType(): IntegrationSubType;

    constructor(
        private _dialogRef: MatDialogRef<T>,
        private _integrations: IntegrationsService,
        @Inject(MAT_DIALOG_DATA) public data: any,
    )
    {
    }

    nameUniqValidator (control: AbstractControl): Observable<ValidationErrors>
    {
        if(control.value === this.data.name)
        {
            return of({});
        }
        return this._integrations.isNameUniq(control.value, this.integrationSubType)
            .pipe(
                debounceTime(1000),
                map((integrations: Integration[]) => {
                    return integrations.length
                        ? { 'nameIsNotUniq' : true }
                        : null
                })
            );
    }

    save(access?: EmployeeBriefly[])
    {
        const integrationType = this.data.isNew
            ? this._integrations.getIntegrationType(this.integrationSubType)
            : this.data.integrationType;

        this._dialogRef.close(
        {
            ...this.data,
            ...this.form.value,
            integrationType,
            integrationSubType: this.integrationSubType,
            access,
        });
    }


}
