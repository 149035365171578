import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslocoModule } from '@ngneat/transloco';
import { IntegrationsService } from '../../integrations.service';
import { IntegrationSubType } from '../../integrations.types';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';
import { DialogTemplateComponent } from '../dialog-template/dialog-template.component';

@Component({
    selector: 'app-aws-dialog',
    standalone: true,
    imports: [
      CommonModule, TranslocoModule, FormsModule, ReactiveFormsModule,
      MatFormFieldModule, MatInputModule, MatIconModule,
      DialogTemplateComponent
    ],
    templateUrl: './aws-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AwsDialogComponent extends BaseDialogComponent<AwsDialogComponent>
{
    get integrationSubType(): IntegrationSubType
    {
        return IntegrationSubType.aws;
    }

    constructor(
        private formBuilder: UntypedFormBuilder,
        private dialogRef: MatDialogRef<AwsDialogComponent>,
        private integrations: IntegrationsService,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ){
        super(dialogRef, integrations, data);

        this.form = this.formBuilder.group({
            name     : [
                data?.name,
                Validators.required,
                this.nameUniqValidator.bind(this)
            ],
            login    : [data?.login, Validators.required],
            password : [data?.password, Validators.required ],
            region   : [data?.region, Validators.required ],
            sender   : [data?.sender, [
                Validators.required,
                Validators.email
            ]],
        });
    }
}
