import { Injectable } from '@angular/core';
import { OracleResponse, OracleStatusCode } from 'app/modules/share/global.types';
import { TranslocoService } from '@ngneat/transloco';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ERROR_MESSAGE_DURATION_TIME, MESSAGE_DURATION_TIME } from 'app/modules/share/global.constants';

@Injectable({
    providedIn: 'root'
})
export class HelperService {

    constructor(
        private _snackBar: MatSnackBar,
        private _translocoService: TranslocoService) { }

    get responseHandler()
    {
        return {
            next: (response: OracleResponse) => this.successHandler(response),
            error: (error: OracleResponse) => this.showErrorMessage(error.Status)
        }
    }

    handler(response: OracleResponse, message: string)
    {
        response.StatusCode === OracleStatusCode.Success
            ? this.showInfoMessage(message)
            : this.showErrorMessage(response.Status);
    }

    successHandler(response: OracleResponse)
    {
        this.handler(response, this._translocoService.translate('savedSuccessfully'));
    }

    removeSuccessHandler(response: OracleResponse)
    {
        this.handler(response, this._translocoService.translate('removeSuccessfully'));
    }

    showInfoMessage(message: string): void
    {
        this.showMessage(message, 'green-snack-bar');
    }

    showErrorMessage(message: string): void
    {
        this.showMessage(message, 'red-snack-bar', ERROR_MESSAGE_DURATION_TIME);
    }

    showMessage(message: string, panelClass: string, duration = MESSAGE_DURATION_TIME): void
    {
        this._snackBar.open(message, 'X', {
            duration,
            verticalPosition: 'top',
            panelClass
        });
    }

}
