import { DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { UserService } from 'app/core/user/user.service';
import { ContactsPanelComponent } from 'app/modules/share/components/contacts-panel/contacts-panel.component';
import { IntegrationsService } from '../../integrations.service';
import { DialogDataInput, Integration, IntegrationSubType } from '../../integrations.types';
import { ResponseQueueComponent } from '../response-queue/response-queue.component';

@Component({
    selector: 'app-dialog-template',
    standalone: true,
    imports: [
        CommonModule,
        TranslocoModule,

        MatButtonModule,
        MatDialogModule,
        MatDividerModule,
        MatIconModule,
        MatInputModule,
        MatFormFieldModule,
        MatTabsModule,

        ContactsPanelComponent,
        ResponseQueueComponent,
    ],
    styleUrls: ['dialog-template.component.scss'],
    templateUrl: './dialog-template.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogTemplateComponent implements OnInit {
    @Input() dialogType: IntegrationSubType;
    @Input() canSave: boolean;
    @Input() data: Integration;
    @Input() queueForm: UntypedFormGroup;
    @Output() save = new EventEmitter();

    isAdmin: boolean;
    dialogData: DialogDataInput;
    isDescriptionCollapsed = true;

    get isMessenger()
    {
        return !!this._integrationService.getIntegrations().find(
            item => item.integrationSubType === this.dialogType
        );
    }

    constructor(
        public dialogRef: DialogRef,
        private _transloco: TranslocoService,
        private _usersService: UserService,
        private _integrationService: IntegrationsService
    ) {
        this.isAdmin = this._usersService.isAdmin;
    }

    ngOnInit(): void
    {
        this.dialogData =
        {
            logoSrc: `/assets/images/integrations/${this.dialogType}-logo.png`,
            title: this.getTranslation(`integrations.${this.dialogType}.title`),
            titleSecondary: this.getTranslation(`integrations.${this.dialogType}.description`),
            image1Src: `/assets/images/integrations/${this.dialogType}-dialog-1.png`,
            image2Src: `/assets/images/integrations/${this.dialogType}-dialog-2.png`,
            image3Src: `/assets/images/integrations/${this.dialogType}-dialog-3.png`,
            text1: this.getTranslation(`integrations.${this.dialogType}.text-1`),
            text2: this.getTranslation(`integrations.${this.dialogType}.text-2`),
            liText1: this.getTranslation(`integrations.${this.dialogType}.li-1-text`),
            liText2: this.getTranslation(`integrations.${this.dialogType}.li-2-text`),
            liText3: this.getTranslation(`integrations.${this.dialogType}.li-3-text`),
            liText4: this.getTranslation(`integrations.${this.dialogType}.li-4-text`),
            text3: this.getTranslation(`integrations.${this.dialogType}.text-3`),
            text4: this.getTranslation(`integrations.${this.dialogType}.text-4`),
        };
    }

    private getTranslation(key: string)
    {
        const value = this._transloco.translate(key);
        return value === key ? null : value;
    }

}
