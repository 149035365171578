import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslocoModule } from '@ngneat/transloco';
import { MAX_PORT_NUMBER } from 'app/modules/share/global.constants';
import { IntegrationsService } from '../../integrations.service';
import { IntegrationSubType } from '../../integrations.types';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';
import { DialogTemplateComponent } from '../dialog-template/dialog-template.component';

@Component({
    selector: 'app-mail-dialog',
    standalone: true,
    imports: [
        CommonModule, TranslocoModule, DialogTemplateComponent, FormsModule, ReactiveFormsModule,
        MatFormFieldModule, MatInputModule, MatIconModule
    ],
    templateUrl: './email-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
    })
export class EmailDialogComponent extends BaseDialogComponent<EmailDialogComponent>
{
    get integrationSubType(): IntegrationSubType
    {
        return IntegrationSubType.email;
    }

    constructor(
        private formBuilder: UntypedFormBuilder,
        private dialogRef: MatDialogRef<EmailDialogComponent>,
        private integrations: IntegrationsService,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ){
        super(dialogRef, integrations, data);

        this.form = this.formBuilder.group({
            name     : [data.name, Validators.required],
            login    : [data.login, [Validators.required, Validators.email]],
            password : [data.password, Validators.required],
            host     : [data.host, Validators.required],
            port     : [data.port, [
                Validators.required,
                Validators.max(MAX_PORT_NUMBER),
            ]],
        });
    }
}
