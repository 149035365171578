import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { OracleResponse } from 'app/modules/share/global.types';
import { EmployeesBrieflyService } from 'app/modules/share/services/employees-briefly.service';
import { HttpClientService } from 'app/modules/share/services/http-client.service';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, Observable, map, of, take, tap } from 'rxjs';
import { integrations } from './integrations.data';
import { Integration, IntegrationSubType, IntegrationType, IntegrationsTypeMap } from './integrations.types';

@Injectable({
    providedIn: 'root'
})
export class IntegrationsService {

    private _integrationList = new BehaviorSubject<Integration[]>([]);
    private _tabIntegrationList = new BehaviorSubject<Integration[]>([]);
    private _currentTab: IntegrationType;

    private _integrations: IntegrationsTypeMap = cloneDeep(integrations);

    constructor(
        private _http: HttpClientService,
        private _employeesBriefly: EmployeesBrieflyService
    ) { }

    get integrationList$(): Observable<Integration[]>
    {
        return this._integrationList.asObservable();
    }

    get tabIntegrationList$(): Observable<Integration[]>
    {
        return this._tabIntegrationList.asObservable();
    }

    get currentTab()
    {
        return this._currentTab;
    }

    setCurrentTab(currentTab = IntegrationType.messengers)
    {
        this._currentTab = currentTab;
        this._tabIntegrationList.next(this.getIntegrations(currentTab));
    }

    get integrationTabList(): Integration[]
    {
        return this._integrationList.getValue()
            .filter(item => !this._currentTab || item.integrationType === this._currentTab.toLocaleUpperCase());
    }

    getIntegrationsByType(type?: IntegrationType): Integration[]
    {
        return this._integrationList.getValue()
            .filter(item => !type || item.integrationType === type.toLocaleUpperCase());
    }

    getIntegrations(currentTab = IntegrationType.messengers): Integration[]
    {
        return Object.values(this._integrations).filter(item =>
            item.integrationType === currentTab.toLocaleUpperCase());
    }

    getComponent(integrationSubType: IntegrationSubType): ComponentType<any>
    {
        return this._integrations[integrationSubType]?.component;
    }

    getIntegrationType(integrationSubType: IntegrationSubType): IntegrationType
    {
        return this._integrations[integrationSubType].integrationType;
    }

    update(integration: Integration): Observable<any>
    {
        return this._http.post('/api/updateIntegration', integration);
    }

    create(integration: Integration): Observable<any>
    {
        return this._http.post('/api/createIntegration', integration)
    }

    delete(integration: Integration): Observable<OracleResponse>
    {
        return this._http.post('/api/deleteIntegration', { integration: integration.id });
    }

    getIntegrationList() : Observable<Integration[]>
    {
        return this._http.get<Integration[]>('/api/getIntegrationList')
            .pipe(
                map(integrations =>
                    integrations.map(integration => ({
                        ...integration,
                        access: integration.access.map((id: number) =>
                            this._employeesBriefly.employeesMap[String(id)]
                        )
                    }))),
                tap(response =>
                    {
                        this._integrationList.next(response);
                        this.setCurrentTab(this._currentTab);
                    }
            ));
    }

    isNameUniq(name: string, integrationSubType: IntegrationSubType)
        : Observable<Integration[]>
    {
        const integrationType = this.getIntegrationType(integrationSubType);
        return this.getIntegrations$(integrationType)
            .pipe(
                take(1),
                map(integrations =>
                    integrations.filter(itm =>
                        itm.integrationSubType === integrationSubType
                        && itm.name === name
                    ))
            );
    }

    get integrations$(): Observable<Integration[]>
    {
        return this.getIntegrations$(this._currentTab);
    }

    getIntegrations$(integrationType: IntegrationType): Observable<Integration[]>
    {
        return this.integrationList$.pipe(
            map(integrationList => integrationList.filter(integration =>
                integration.integrationType === integrationType
            ))
        );
    }
}
