export const ADMIN_ROLE = 'ADMIN'

export interface User
{
    id: string;
    name?: string;
    email: string;
    avatar?: string;
    status?: string;
    first_name?: string;
    userRole?: string
}
