import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CompanyService } from 'app/modules/admin/pages/settings/company/company.service';
import { CompanyInfo } from 'app/modules/admin/pages/settings/company/company.types';
import { BehaviorSubject, Observable } from 'rxjs';
import { LogoType } from '../global.types';
import { LOGO_DEFAULT_PATH } from '../global.constants';

@Injectable({
    providedIn: 'root'
})
export class LogoImageService {

    private readonly _logos = {
        mainLogoLight: {
            fileName: 'logoLight.png',
            observable: new BehaviorSubject<string>('')
        },
        mainLogoDark: {
            fileName: 'logoDark.png',
            observable: new BehaviorSubject<string>('')
        },
        secondaryLogoLight: {
            fileName: 'logo-text.png',
            observable: new BehaviorSubject<string>('')
        },
        secondaryLogoDark: {
            fileName: 'logo-text-on-dark.png',
            observable: new BehaviorSubject<string>('')
        },
    }

    constructor(
        private _companyService: CompanyService
    )
    {
        this._companyService.companyInfo$
            .pipe(takeUntilDestroyed())
            .subscribe(companyInfo => this.resolveLogoImage(companyInfo) )
    }

    getObservable(logoType: LogoType): Observable<string>
    {
        return this._logos[logoType].observable.asObservable();
    }

    resolveLogoImage(companyInfo: CompanyInfo): void
    {
        if(companyInfo) {
            Object.keys(this._logos).forEach(key =>
                this._logos[key].observable.next(companyInfo[key])
            );
        }
        else
        {
            Object.values(this._logos).forEach(logo =>
                logo.observable.next(LOGO_DEFAULT_PATH + logo.fileName)
            );
        }
    }

}
