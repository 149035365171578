import { Injectable } from '@angular/core';
import { ChatService } from './chat.service';
import { QuickChatService } from 'app/layout/common/quick-chat/quick-chat.service';
import { WsMessagesService } from 'app/modules/share/services/ws-messages.service';
import { WsIncomeMessage } from 'app/modules/share/global.types';
import { BehaviorSubject, filter, firstValueFrom } from 'rxjs';
import { Chat, IChatService } from './chat.types';
import { keyBy, mapValues, sum, values } from 'lodash';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable({
    providedIn: 'root'
})
export class ChatWsService {

    private _unreadCount = new BehaviorSubject(0);
    private _hasNewMessages = new BehaviorSubject<Chat[]>([]);

    get hasNewMessages$()
    {
        return this._hasNewMessages.asObservable();
    }

    get unreadCount$()
    {
        return this._unreadCount.asObservable();
    }

    get unreadCount()
    {
        return this._unreadCount.getValue();
    }

    set unreadCount(count: number)
    {
        this._unreadCount.next(count)
    }

    constructor(
        private _wsService: WsMessagesService,
        private _chatService: ChatService,
        private _quickChatService: QuickChatService,
    ) {

        this._chatService.chats$
            .pipe(takeUntilDestroyed())
            .subscribe(chats =>
                this._unreadCount.next(this._getUnreadCount(chats))
            );

        this._wsService.ws$
            .pipe(
                takeUntilDestroyed(),
                filter(message => Boolean(message.chats)))
            .subscribe(async (message: WsIncomeMessage) =>
                {
                if(!message.chats.length) return;

                const chats = this._chatService.chats;
                const unreadCount = this._getUnreadCount(message.chats);
                const unreadCountPrev = this._unreadCount.getValue();
                if(unreadCountPrev !== unreadCount)
                {
                    const chatsMap = mapValues(keyBy(chats, 'id'));
                    message.chats.forEach(chat =>
                        (chatsMap[chat.id] = this._mergeChat(chatsMap[chat.id], chat)));
                    this._chatService.chats = values(chatsMap);
                    this._hasNewMessages.next(message.chats);

                    const selectedChat = this._chatService.chat;
                    const newSelectedChat = selectedChat && chats.find(c => c.id === selectedChat.id);
                    selectedChat && await this._updateMessagesStatus(this._chatService, newSelectedChat);

                    const selectedQuickChat = this._quickChatService.chat
                    const newSelectedQuickChat = selectedQuickChat && chats.find(c => c.id === selectedQuickChat.id);
                    if(newSelectedQuickChat)
                    {
                        if(newSelectedQuickChat.id === selectedChat?.id)
                        {
                            this._quickChatService.chat = this._chatService.chat;
                        } else
                        {
                            this._quickChatService.opened
                                && await this._updateMessagesStatus(this._quickChatService, newSelectedQuickChat);
                        }
                    }

                }

            });
        }

    updateChatMessages(chatService: IChatService = this._chatService)
    {
        chatService.chat && this._updateMessagesStatus(chatService, chatService.chat);
    }

    private async _updateMessagesStatus(chatService: IChatService, chat: Chat)
    {
        const messages = await firstValueFrom(this._chatService.getMessages(chat.id));
        await firstValueFrom(this._chatService.setMessagesRead(chat.id));
        const chats = this._chatService.chats;
        const idx = chats.findIndex(itm => itm.id === chat.id);

        this.unreadCount = this._unreadCount.getValue() - chat.unreadCount;

        chats[idx] = {
            ...this._mergeChat(chats[idx], chat),
            messages,
            unreadCount: 0,
        };
        this._chatService.chats = chats;

        chatService.chat = chats[idx];
    }

    private _mergeChat(chat: Chat, newChat: Chat): Chat
    {
        return {
            ...chat,
            unreadCount:  newChat.unreadCount,
            lastMessage: newChat.lastMessage,
            lastMessageAt: newChat.lastMessageAt,
        };
    }

    private _getUnreadCount(chats: Chat[]): number
    {
        return sum(chats.map(chat => chat.unreadCount)) || 0;
    }

}
