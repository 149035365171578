import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Navigation, NavigationItem } from 'app/core/navigation/navigation.types';
import { cloneDeep } from 'lodash-es';
import { Observable, ReplaySubject, Subject, concatMap, map, of, tap } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { UserService } from '../user/user.service';
import { compactNavigation, futuristicNavigation, horizontalNavigation } from './data';

@Injectable({providedIn: 'root'})
export class NavigationService
{
    private _navigation$: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);
    private _availablePaths$ = new Subject<string[]>();
    private readonly _compactNavigation: NavigationItem[] = compactNavigation;
    private readonly _futuristicNavigation: NavigationItem[] = futuristicNavigation;
    private readonly _horizontalNavigation: NavigationItem[] = horizontalNavigation;
    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService,
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for navigation
     */
    get navigation$(): Observable<Navigation>
    {
        return this._navigation$.asObservable();
    }

    get availablePaths$(): Observable<string[]>
    {
        return this._availablePaths$.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all navigation data
     */
    get(): Observable<Navigation>
    {
        return this._httpClient.get<NavigationItem[]>(
            environment.apiBaseUrl + '/api/GetUserMenu', { params: { login : this._userService.login }})
            .pipe(
                map(userMenu => this._filterNavigationMenu(userMenu)),
                tap(userMenu =>
                    {
                        this._availablePaths$.next(this._getAvailableMenuPaths(userMenu));
                    }
                ),
                concatMap(userMenu => {

                    const navigation = userMenu;

                    this._compactNavigation.forEach((compactNavItem) =>
                    {
                        navigation.forEach((defaultNavItem) =>
                        {
                            if ( defaultNavItem.id === compactNavItem.id )
                            {
                                compactNavItem.children = cloneDeep(defaultNavItem.children);
                            }
                        });
                    });

                    // Fill futuristic navigation children using the default navigation
                    this._futuristicNavigation.forEach((futuristicNavItem) =>
                    {
                        navigation.forEach((defaultNavItem) =>
                        {
                            if ( defaultNavItem.id === futuristicNavItem.id )
                            {
                                futuristicNavItem.children = cloneDeep(defaultNavItem.children);
                            }
                        });
                    });

                    // Fill horizontal navigation children using the default navigation
                    this._horizontalNavigation.forEach((horizontalNavItem) =>
                    {
                        navigation.forEach((defaultNavItem) =>
                        {
                            if ( defaultNavItem.id === horizontalNavItem.id )
                            {
                                horizontalNavItem.children = cloneDeep(defaultNavItem.children);
                            }
                        });
                    });

                    return of({
                            compact   : cloneDeep(this._compactNavigation),
                            default   : cloneDeep(navigation),
                            futuristic: cloneDeep(this._futuristicNavigation),
                            horizontal: cloneDeep(this._horizontalNavigation),
                        } as Navigation,
                    );
                }),

                tap(navigation =>
                {
                    this._navigation$.next(navigation);
                }),
            );
    }

    private _filterNavigationMenu(navigationItems: NavigationItem[]): NavigationItem[]
    {
        let filteredNavigationItems = navigationItems.filter(menu =>
            !!menu.available && this._userService.isMenuItemAvailable(menu.id));

        filteredNavigationItems.forEach(navigationItem =>
        {
            navigationItem.children &&
                (navigationItem.children = this._filterNavigationMenu(cloneDeep(navigationItem.children)));
        });
        return filteredNavigationItems;
    }

    private _getAvailableMenuPaths(navigationItems: NavigationItem[]): string[]
    {
        return navigationItems.reduce((acc, item) =>
        {
            if(item.link)
            {
                acc.push(item.link);
            }

            if(item.children)
            {
                acc = acc.concat(this._getAvailableMenuPaths(item.children));
            }
            return acc;

        }, []);
    }

}
