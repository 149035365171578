import { Pipe, PipeTransform } from '@angular/core';
import { parsePhoneNumber, CountryCode } from 'libphonenumber-js';

@Pipe({
    name: 'phone',
    standalone: true,
})
export class PhonePipe implements PipeTransform {
    transform(phoneValue: number | string, locale: CountryCode = 'UA'): string {
        const stringPhone = String(phoneValue);
        const phoneNumber = parsePhoneNumber(stringPhone, locale);
        return phoneNumber.formatInternational();
    }
}
