import { EmployeeBriefly } from "app/modules/share/global.types";

export interface IChatService
{
    get chat(): Chat;
    set chat(chat: Chat);
    get chats(): Chat[];
    set chats(chat: Chat[]);
}

export enum ChatType {
    'single' = 'SINGLE',
    'group' = 'GROUP',
    'messenger' = 'MESSAGER',
    'room' = 'ROOM'
}

export type Profile =
{
    id?: string;
    name?: string;
    email?: string;
    avatar?: string;
    about?: string;
}

export type Contact =
{
    id?: string;
    avatar?: string;
    name?: string;
    about?: string;
    details?: {
        emails?: {
            email?: string;
            label?: string;
        }[];
        phoneNumbers?: {
            country?: string;
            phoneNumber?: string;
            label?: string;
        }[];
        title?: string;
        company?: string;
        birthday?: string;
        address?: string;
    };
    attachments?: {
        media?: any[];
        docs?: any[];
        links?: any[];
    };
}

export type Chat =
{
    id?: string;
    name?: string;
    type?: ChatType;
    isNew?: boolean;
    canRemove?: boolean;
    contactId?: string;
    contactIds?: string[];
    contact?: EmployeeBriefly;
    contacts?: EmployeeBriefly[];
    unreadCount?: number;
    muted?: boolean;
    lastMessage?: string;
    lastMessageAt?: string;
    messages?: Message[];
}

export type Message = {
    id?: string;
    chatId?: string;
    contactId?: string;
    contact?: EmployeeBriefly;
    isMine?: boolean;
    value?: string;
    createdAt?: string;
}
