import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslocoModule } from '@ngneat/transloco';
import { IntegrationsService } from '../../integrations.service';
import { IntegrationSubType } from '../../integrations.types';
import { BaseMessengerDialogComponent } from '../base-messenger-dialog/base-messenger-dialog.component';
import { DialogTemplateComponent } from "../dialog-template/dialog-template.component";

@Component({
    selector: 'app-viber-dialog',
    standalone: true,
    templateUrl: './viber-dialog.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        TranslocoModule,
        FormsModule,
        ReactiveFormsModule,

        MatFormFieldModule,
        MatInputModule,
        MatIconModule,

        DialogTemplateComponent,
    ]
})
export class ViberDialogComponent extends BaseMessengerDialogComponent<ViberDialogComponent>
{
    get integrationSubType(): IntegrationSubType
    {
        return IntegrationSubType.viber;
    }

    constructor(
        private formBuilder: UntypedFormBuilder,
        private __dialogRef: MatDialogRef<ViberDialogComponent>,
        private __integrations: IntegrationsService,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        super(__dialogRef, __integrations, data);

        this.form = this.formBuilder.group({
            name:
            [
                data?.name,
                Validators.required,
                this.nameUniqValidator.bind(this)
            ],
            apiKey:
            [
                data?.apiKey,
                Validators.required
            ],
/*             channel:
            [
                data?.channel,
                [
                    Validators.required,
                    Validators.pattern(/^[0-9]*$/)
                ]
            ], */
        });
    }

}
