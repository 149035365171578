import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslocoModule } from '@ngneat/transloco';
import { IntegrationsService } from '../../integrations.service';
import { IntegrationSubType } from '../../integrations.types';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';
import { DialogTemplateComponent } from '../dialog-template/dialog-template.component';

@Component({
    selector: 'app-binotel-dialog',
    standalone: true,
    imports: [
      CommonModule,
      TranslocoModule,
      FormsModule,
      ReactiveFormsModule,

      MatFormFieldModule,
      MatInputModule,
      MatIconModule,

      DialogTemplateComponent
    ],
    templateUrl: './binotel-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BinotelDialogComponent extends BaseDialogComponent<BinotelDialogComponent>
{
    get integrationSubType(): IntegrationSubType
    {
        return IntegrationSubType.binotel;
    }

    constructor(
        private formBuilder: UntypedFormBuilder,
        private dialogRef: MatDialogRef<BinotelDialogComponent>,
        private integrations: IntegrationsService,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        super(dialogRef, integrations, data);

        this.form = this.formBuilder.group({
            name        : [
                data?.name,
                [
                    Validators.required,
                ]
            ],
            internalNumber        : [
                data?.internalNumber,
                [
                    Validators.required,
                ]
            ],
            companyId        : [
                data?.companyId,
                [
                    Validators.required,
                ]
            ],
            key    : [
                data?.key,
                [
                    Validators.required
                ]
            ],
            secret    : [
                data?.secret,
                Validators.required
            ],
        });
    }

}
