<app-dialog-template
    class="h-full"
    [canSave]="form.valid"
    [dialogType]="integrationSubType"
    [data]="data"
    (save)="save($event)"
>
    <form [formGroup]="form">
        <div class="grid mx-0 grid-cols-1 sm:grid-cols-2 gap-4 w-full mt-4">
            <div class="sm:col-span-1">
                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                    <mat-label>{{ 'name' | transloco }}</mat-label>
                    <mat-icon matPrefix class="icon-size-5" svgIcon="heroicons_solid:user-circle"></mat-icon>
                    <input matInput formControlName="name">
                    <mat-error *ngIf="f.name.hasError('required')">
                        {{ 'requiredFieldError' | transloco }}
                    </mat-error>
                    <mat-error *ngIf="f.name.hasError('nameIsNotUniq')">
                        {{ 'integrations.nameUniqError' | transloco }}
                    </mat-error>
                </mat-form-field>
            </div>

            <mat-form-field [subscriptSizing]="'dynamic'">
                <mat-label>{{ 'integrations.messengerType' | transloco }}</mat-label>
                <mat-select formControlName="messengerType">
                    <mat-option *ngFor="let messengerType of messengerTypes" [value]="messengerType">
                        {{ 'integrations.messenger.' + messengerType | transloco }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="f.messengerType.hasError('required')">
                    {{ 'requiredFieldError' | transloco }}
                </mat-error>
            </mat-form-field>

        </div>
        <div class="grid mx-0 grid-cols-1 sm:grid-cols-2 gap-4 w-full mt-4">

            <div class="sm:col-span-1">
                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                    <mat-label>{{ 'integrations.apiKey' | transloco }}</mat-label>
                    <mat-icon class="icon-size-5" svgIcon="heroicons_solid:key" matPrefix></mat-icon>
                    <input type="password" matInput formControlName="apiKey">
                    <mat-error *ngIf="f.apiKey.hasError('pattern')">
                        {{ 'error.invalidURL' | transloco }}
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="sm:col-span-1">
                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                    <mat-label>{{ 'integrations.apiUrl' | transloco }}</mat-label>
                    <mat-icon class="icon-size-5" svgIcon="heroicons_solid:link" matPrefix></mat-icon>
                    <input matInput formControlName="apiUrl">
                    <mat-error *ngIf="f.apiUrl.hasError('pattern')">
                        {{ 'error.invalidURL' | transloco }}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <div class="grid mx-0 grid-cols-1 sm:grid-cols-2 gap-4 w-full mt-4">
            <div class="sm:col-span-1">
                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                    <mat-label>{{ 'integrations.channel' | transloco }}</mat-label>
                    <mat-icon matPrefix class="icon-size-5" svgIcon="heroicons_solid:cpu-chip"></mat-icon>
                    <input matInput formControlName="channel">
                    <mat-error *ngIf="f.channel.hasError('pattern')">
                        {{ 'error.invalidNumber' | transloco }}
                    </mat-error>
                </mat-form-field>
            </div>

        </div>
    </form>

</app-dialog-template>
