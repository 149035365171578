import { Route } from '@angular/router';
import { LayoutComponent } from 'app/layout/layout.component';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';

export const initCompanyRoutes: Route[] = [

    // Routes for new company
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'auth-init',
        },
        children: [
            { path: '', pathMatch : 'full', redirectTo: 'init' },
            { path: 'init', component: RegisterComponent },
            { path: 'sign-in', component: LoginComponent }
        ]
    },

    { path: '**', pathMatch : 'full', redirectTo: 'init' },
]
