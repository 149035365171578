import { ChangeDetectionStrategy, Component, DestroyRef, Inject, inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AsyncPipe, NgIf } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { AudioCallService } from './audio-call.service';
import { AudioCallDialogData, AudioCallState, CallContact, CallMediaStreams } from './audio-call.types';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable } from 'rxjs';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { PhonePipe } from 'app/modules/share/pipes/phone.pipe';
import { IntegrationSubType } from 'app/modules/admin/apps/developers/integrations/integrations.types';
import { DateTime } from 'luxon';

@Component({
    selector: 'app-audio-call',
    templateUrl: './audio-call.component.html',
    styleUrls: ['./audio-call.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [TranslocoModule, AsyncPipe, MatIconModule, MatButtonModule, MatProgressBarModule, NgIf, PhonePipe],
})
export class AudioCallComponent implements OnInit {
    callStatus: Observable<AudioCallState>;
    callStreams$: Observable<CallMediaStreams>;
    contact: Observable<CallContact>;
    isMinimized: boolean  = false

    private destroyRef = inject(DestroyRef);

    get telephonyIntegrationType() {
        return this._audioCallService.telephonyIntegrationType
    }

    get userTelephonyIntegration() {
        return this._audioCallService.userTelephonyIntegration
    }

    constructor(
        private _audioCallService: AudioCallService,
        public dialogRef: MatDialogRef<AudioCallComponent>,
        @Inject(MAT_DIALOG_DATA) public data: AudioCallDialogData,
    ) {}

    ngOnInit() {
        this.callStatus = this._audioCallService.callStatus$;
        this.callStreams$ = this._audioCallService.callStreams$;
        this.contact = this._audioCallService.contact$;
        this._audioCallService.callStreams$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(stream => {
            if (stream) {
                this.assignStream(stream.localStream);
                this.assignStream(stream.remoteStream);
            }
        });
    }

    assignStream(stream: MediaStream, element?: HTMLMediaElement): void {
        if (!stream) return;

        const audio = new Audio();
        audio.srcObject = stream;
        audio.play().catch((error: Error) => console.error('Failed to play media', error));

        stream.onaddtrack = (): void => {
            audio.load();
            audio.play().catch((error: Error) => console.error('Failed to play remote media on add track', error));
        };

        stream.onremovetrack = (): void => {
            audio.load();
            audio.play().catch((error: Error) => console.error('Failed to play remote media on remove track', error));
        };
    }

    endCall() {
        this._audioCallService.endCall();
        this.dialogRef.close();
    }

    acceptCall() {
        this._audioCallService.acceptCall();
    }

    setIsMinimize(value: boolean) {
        this.isMinimized = value
    }

    protected readonly IntegrationSubType = IntegrationSubType;
    protected readonly DateTime = DateTime;
}
