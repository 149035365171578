<h1 mat-dialog-title class="text-xl">
    {{ 'chats.createChat' | transloco }}
 </h1>
<div mat-dialog-content class="mt-4">

    <mat-form-field class="w-full">
        <mat-label>
            {{ 'chats.chatName' | transloco }}
        </mat-label>
        <input matInput [(ngModel)]="chat.name">
    </mat-form-field>

<!--     <mat-form-field class="w-full">
        <mat-label>Chat Type</mat-label>
        <mat-select [(ngModel)]="chat.type">
            <mat-option *ngFor="let type of chatTypes" [value]="type">
                {{ type | titlecase }}
            </mat-option>
        </mat-select>
    </mat-form-field> -->

    <div class="w-full">

        <div class="flex justify-between">
            <app-contacts-panel
                class="w-full"
                labelText="{{ 'chats.members' | transloco }}"
                [contactsSelected]="chat.contacts"
                (onSelect)="onRecipientsSelected($event)"
            ></app-contacts-panel>
        </div>
    </div>
</div>
<div mat-dialog-actions class="flex justify-end">
    <button mat-stroked-button (click)="onNoClick()">
        {{ 'noThanks' | transloco }}
    </button>
    <button
        mat-stroked-button
        color="primary"
        [mat-dialog-close]="data"
        cdkFocusInitial
        [disabled]="!chat.contacts?.length || !chat.name"
    >
        {{ 'ok' | transloco }}
    </button>
</div>
