import { Injectable,Component, OnInit  } from '@angular/core';
import { UserService } from 'app/core/user/user.service';
import { catchError, Observable, of, switchMap, tap, throwError,map } from 'rxjs';
import { Credentials, LoginResponse, UserPayload, UserResponse } from './auth.types';
import { OracleResponse, OracleStatusCode } from '../../modules/share/global.types';
import { TranslocoService } from '@ngneat/transloco';
import { ActivatedRoute } from '@angular/router';
import { HttpClientService } from 'app/modules/share/services/http-client.service';

@Injectable({providedIn: 'root'})
export class AuthService
{
    private _authenticated: boolean = false;
    token: string;
    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClientService,
        private _userService: UserService,
        private _transloco: TranslocoService,
        private _activatedRoute: ActivatedRoute
    )
    {
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string)
    {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string
    {
        return localStorage.getItem('accessToken') ?? '';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any>
    {
        return this._httpClient.post<any>('/api/forgotPassword', { email })
            .pipe(
                map(response => {
                    // Проверяем StatusCode в ответе
                    if (response.StatusCode === 200) {
                        // Если StatusCode равен 200, возвращаем email
                        return true
                    } else {
                        // Иначе возвращаем пустое значение
                        throw new Error('Failed to reset password');
                    }
                }),
                switchMap(emailOrError => {
                    // Передаем email дальше, если он есть, иначе ошибка будет перехвачена
                    return of(emailOrError);
                }),
            );
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string): Observable<any>
    {
        return this._activatedRoute.queryParams.pipe(
            switchMap(params => {
                const token = params['token'];

                if (!token) {
                    return throwError(() => new Error('Token is missing'));
                }


                return this._httpClient.post<any>('/api/reset-password', { password, token }).pipe(
                    map(response => {
                        // Проверяем StatusCode в ответе
                        if (response.StatusCode === 200) {
                            // Если StatusCode равен 200, возвращаем email
                            return true
                        } else {
                            // Иначе возвращаем пустое значение
                            throw new Error('Failed to reset password');
                        }
                    }),
                    switchMap(emailOrError => {
                        // Передаем email дальше, если он есть, иначе ошибка будет перехвачена
                        return of(emailOrError);
                    }),
                );
            })
        );
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: Credentials): Observable<any>
    {
        // Throw error, if the user is already logged in
        if ( this._authenticated )
        {
            return throwError('User is already logged in.');
        }

        localStorage.setItem('email', credentials.email);

        return this._httpClient.post<LoginResponse>('/api/login',
            {
                 ...credentials,
                 language: this._transloco.getActiveLang()
            })
            .pipe(
                switchMap((response: LoginResponse) =>
                {
                    if(response.StatusCode === OracleStatusCode.Success)
                    {
                        this._userService.user = response.data.user;
                        this.accessToken = response.data.accessToken;
                        this._authenticated = true;
                    }
                    return of(response);
                })
        );

    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any>
    {
        // Sign in using the token
        return this._httpClient.post('/api/sign-in-with-token', {
            accessToken: this.accessToken
        }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
                // Replace the access token with the new one if it's available on
                // the response object.
                //
                // This is an added optional step for better security. Once you sign
                // in using the token, you should generate a new one on the server
                // side and attach it to the response object. Then the following
                // piece of code can replace the token with the refreshed one.
                if ( response.accessToken )
                {
                    this.accessToken = response.accessToken;
                }

                // Set the authenticated flag to true
                this._authenticated = true;


                this._userService.user = response;

                // Return true
                return of(true);
            }),
        );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any>
    {
        // Remove the access token from the local storage
        localStorage.removeItem('accessToken');
        localStorage.removeItem('id');
        localStorage.removeItem('name');
        localStorage.removeItem('email');
        localStorage.removeItem('avatar');
        localStorage.removeItem('status');
        localStorage.removeItem('first_name');
        localStorage.removeItem('userRole');

        // Set the authenticated flag to false
        this._authenticated = false;

        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: UserPayload) : Observable<OracleResponse>
    {
        return this._httpClient.post<OracleResponse>('/api/newUser',
        {
            ...user,
            language: this._transloco.getActiveLang()
        });
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any>
    {
        return this._httpClient.post('/api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean>
    {
        // Check if the user is logged in
        if ( this._authenticated )
        {
            return of(true);
        }

        // Check the access token availability
        if ( !this.accessToken )
        {
            return of(false);
        }

        // Check the access token expire date
   //     if ( AuthUtils.isTokenExpired(this.accessToken) )
   //     {
   //         this._router.navigate(['/sign-out']);
    //        return of(false);

    //    }

        // If the access token exists, and it didn't expire, sign in using it
        return this.signInUsingToken().pipe(
            tap(authenticated =>
                !authenticated === null && (this.accessToken = null)
            )
        );
    }

}
