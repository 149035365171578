import { DestroyRef, Directive, ElementRef, Input, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LogoType } from '../global.types';
import { LogoImageService } from '../services/logo-image.service';

@Directive({
    standalone: true,
    selector: '[appLogoImageResolve]'
})
export class LogoImageResolveDirective implements OnInit
{
    @Input() logoType: LogoType;
    private destroyRef = inject(DestroyRef);

    constructor(
        private _el: ElementRef,
        private _logoImageService: LogoImageService
    ) {
    }

    ngOnInit(): void
    {
        this._logoImageService.getObservable(this.logoType)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(
                src => this._el.nativeElement.src = src
            );
    }

}
