import { Injectable } from '@angular/core';
import { ChatService } from 'app/modules/admin/apps/chat/chat.service';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { Chat, Contact, IChatService, Message } from "../../../modules/admin/apps/chat/chat.types";

@Injectable({providedIn: 'root'})
export class QuickChatService implements IChatService
{
    private _chat: BehaviorSubject<Chat> = new BehaviorSubject(null);
    private _opened$ = new BehaviorSubject(false);

    /**
     * Constructor
     */
    constructor(
        private _chatService: ChatService,
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    get opened(): boolean
    {
        return this._opened$.getValue();
    }

    set opened(opened: boolean)
    {
        this._opened$.next(opened);
    }

    /**
     * Getter for chat
     */
    get chat$(): Observable<Chat>
    {
        return this._chat.asObservable();
    }

    get chat(): Chat
    {
        return this._chat.getValue();
    }

    set chat(chat: Chat)
    {
        this._chat.next(chat);
    }

    get chats(): Chat[]
    {
        return this._chatService.chats;
    }

    set chats(chats: Chat[])
    {
        this._chatService.chats = chats;
    }

    /**
     * Getter for chat
     */
    get chats$(): Observable<Chat[]>
    {
        return this._chatService.chats$;
    }

    get contacts$(): Observable<Contact[]>
    {
        return this._chatService.contacts$;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    sendMessage(chat: Chat, message: string): Observable<Message[]>
    {
        return this._chatService.sendMessage(chat, message);
    }

    getMessages(chatId: string): Observable<Message[]>
    {
        return this._chatService.getMessages(chatId);
    }

    /**
     * Get chat
     *
     * @param id
     */
    getChatById(id: string): Observable<any>
    {
         const chat = this.chats.find(chat => chat.id === id);

        if ( !chat )
        {
            return throwError('Could not found chat with id of ' + id + '!');
        }
        else {
            chat.contact = this._chatService.contacts.find(
                contact => contact.id === chat.contactId);
            this._chat.next(chat);
        }
        return of(chat);
    }

    resetChat(): void
    {
        this._chat.next(null);
    }

    setMessagesRead(id: string): Observable<Message[]>
    {
        return this._chatService.setMessagesRead(id);
    }
}
