<app-dialog-template
    class="h-full"
    [canSave]="form.valid"
    [dialogType]="integrationSubType"
    [data]="data"
    (save)="save($event)"
>
    <form [formGroup]="form">
        <div class="grid mx-0 sm:grid-cols-2 gap-4 w-full mt-4">
            <div class="sm:col-span-1">
                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                    <mat-label>{{ 'name' | transloco }}</mat-label>
                    <mat-icon matPrefix class="icon-size-5" svgIcon="heroicons_solid:identification"></mat-icon>
                    <input matInput formControlName="name">
                    <mat-error *ngIf="f.name.hasError('required')">
                        {{ 'requiredFieldError' | transloco }}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="sm:col-span-1">
                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                    <mat-label>{{ 'integrations.companyId' | transloco }}</mat-label>
                    <mat-icon matPrefix class="icon-size-5" svgIcon="heroicons_solid:building-office"></mat-icon>
                    <input matInput formControlName="companyId">
                    <mat-error *ngIf="f.companyId.hasError('required')">
                        {{ 'requiredFieldError' | transloco }}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="grid mx-0 sm:grid-cols-2 gap-4 w-full mt-4">
            <div class="sm:col-span-1">
                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                    <mat-label>{{ 'integrations.key' | transloco }}</mat-label>
                    <mat-icon matPrefix class="icon-size-5" svgIcon="heroicons_solid:key"></mat-icon>
                    <input matInput formControlName="key">
                    <mat-error *ngIf="f.key.hasError('required')">
                        {{ 'requiredFieldError' | transloco }}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="sm:col-span-1">
                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                    <mat-label>{{ 'integrations.secret' | transloco }}</mat-label>
                    <mat-icon matPrefix class="icon-size-5" svgIcon="heroicons_solid:lock-closed"></mat-icon>
                    <input type="password" matInput formControlName="secret">
                    <mat-error *ngIf="f.secret.hasError('required')">
                        {{ 'requiredFieldError' | transloco }}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="grid mx-0 sm:grid-cols-2 gap-4 w-full mt-4">
            <div class="sm:col-span-1">
                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                    <mat-label>{{ 'integrations.internalNumber' | transloco }}</mat-label>
                    <mat-icon matPrefix class="icon-size-5" svgIcon="heroicons_solid:phone"></mat-icon>
                    <input matInput formControlName="internalNumber">
                    <mat-error *ngIf="f.internalNumber.hasError('required')">
                        {{ 'requiredFieldError' | transloco }}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </form>
</app-dialog-template>
