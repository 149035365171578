<mat-form-field class="w-full {{ panelClass }}" [subscriptSizing]="'dynamic'">

    <mat-label *ngIf="labelText">
        {{ labelText }}
    </mat-label>

    <input matInput hidden>

    <mat-chip-listbox class="flex flex-wrap items-center py-2 " #panel>

        <mat-chip disableRipple color="accent-300" *ngFor="let person of contactsSelected;">
            {{ person.fullName }}
        </mat-chip>

        <button
            class="ml-2"
            mat-stroked-button
            color="primary"
            (click)="contactPicker.open()"
        >
            <div class="flex items-center">
                <ng-container *ngIf="contactsSelected && contactsSelected.length">
                    <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:pencil-square'"></mat-icon>
                    <span class="ml-2 text-md font-medium">
                        {{ 'edit' | transloco }}
                    </span>
                </ng-container>
                <ng-container *ngIf="!contactsSelected || !contactsSelected.length">
                    <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:plus-circle'"></mat-icon>
                    <span class="ml-2 text-md font-medium">
                        {{ 'add' | transloco }}
                    </span>
                </ng-container>
            </div>

        </button>
    </mat-chip-listbox>
</mat-form-field>


<app-contact-picker
    #contactPicker
    [contactsSelected]="contactsSelected"
    [contacts]="contacts$ | async"
    [connectedTo]="panel._elementRef"
    (onSelect)="select($event)">
</app-contact-picker>
