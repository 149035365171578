<div class="w-full">

    <form #signInNgForm="ngForm" [formGroup]="signInForm" class="p-4">
        <fuse-alert *ngIf="showAlert"> {{ alert }} </fuse-alert>
        <mat-form-field class="w-full mb-4">
            <mat-label>{{ 'companyInit.email' | transloco }}</mat-label>
            <input matInput formControlName="email" type="email">
            <mat-error *ngIf="signInForm.controls['email'].invalid">
                {{ 'companyInit.emailError' | transloco }}
            </mat-error>
        </mat-form-field>
        <mat-form-field class="w-full mb-4">
            <mat-label>{{ 'password' | transloco }}</mat-label>
            <input matInput formControlName="password" type="password">
            <mat-error *ngIf="signInForm.controls['password'].invalid">
                {{ 'auth.passwordRequiredError' | transloco }}
            </mat-error>
        </mat-form-field>

        <div class="inline-flex items-center justify-between w-full mt-1.5">
            <mat-checkbox
                class="-ml-2"
                [color]="'primary'"
                [formControlName]="'rememberMe'">
                    {{ 'auth.rememberMe' | transloco }}
            </mat-checkbox>
        </div>

        <div class="mt-4 text-center">
            <button
                class="w-1/2"
                [disabled]="signInForm.disabled"
                mat-raised-button [color]="'primary'"
                (click)="login()">
                <span>{{ 'auth.signIn' | transloco }}</span>
                <mat-progress-spinner
                    *ngIf="signInForm.disabled"
                    [diameter]="24"
                    [mode]="'indeterminate'"></mat-progress-spinner>
            </button>
        </div>

    </form>

    <div class="text-center">
        <span>
            <a class="text-primary-500 hover:underline mat-raised-button" [routerLink]="['/init']">
                {{ 'companyInit.register' | transloco }}
            </a>&nbsp;
            {{ 'companyInit.newCompany' | transloco }}
        </span>
    </div>

</div>
