import { Injectable } from '@angular/core';
import { Notification } from 'app/layout/common/notifications/notifications.types';
import { BehaviorSubject, Observable, ReplaySubject, merge, tap, filter } from 'rxjs';
import { UserService } from 'app/core/user/user.service';
import { OracleResponse, WsIncomeMessage } from 'app/modules/share/global.types';
import { WsMessagesService } from 'app/modules/share/services/ws-messages.service';
import { keyBy, mapValues, values } from 'lodash';
import { NOTIFICATION_SOUND_FILENAME } from 'app/modules/share/global.constants';
import { ChatWsService } from 'app/modules/admin/apps/chat/chat-ws.service';
import { HttpClientService } from 'app/modules/share/services/http-client.service';

@Injectable({providedIn: 'root'})
export class NotificationsService
{
    private _notifications$: BehaviorSubject<Notification[]> = new BehaviorSubject<Notification[]>([]);
    private _unreadCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    private _hasNewMessages$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private _audio: HTMLAudioElement;

    /**
     * Constructor
     */
    constructor(
        private _http: HttpClientService,
        private _userService: UserService,
        private _chatsWsService: ChatWsService,
        private _wsService: WsMessagesService)
    {
        this._notifications$.subscribe(chats => {
            this._unreadCount$.next(
                this._getUnreadCount(chats)
            );
        });

        merge(this._hasNewMessages$, this._chatsWsService.hasNewMessages$)
            .subscribe(_ =>
                this.playNotificationAudio()
        );


        this._wsService.ws$.pipe(filter(message => Boolean(message.notifications))).subscribe((message: WsIncomeMessage) => {
            // console.log('ws message:', message);
            const notifications = this._notifications$.getValue();
            const unreadCount = this._getUnreadCount(message.notifications);
            const unreadCountPrev = this._unreadCount$.getValue();

            if(unreadCount && unreadCount !== unreadCountPrev) {
                const notificationsMap = mapValues(keyBy(notifications, 'id'))

                message.notifications.forEach(notification =>
                        notificationsMap[notification.id] = notification);

                this._notifications$.next(values(notificationsMap));
                this._unreadCount$.next(unreadCount);

                unreadCountPrev < unreadCount &&
                    this._hasNewMessages$.next(true);


                console.log('notifications', notifications, Object.values(notificationsMap));
            }
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for notifications
     */
    get notifications$(): Observable<Notification[]>
    {
        return this._notifications$.asObservable();
    }

    get unreadCount$()
    {
        return this._unreadCount$.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all notifications
     */
    getAll(): Observable<Notification[]>
    {
        return this._http.get<Notification[]>('/api/getNotifications', {
                login: this._userService.login
            })
            .pipe(tap(notifications => {
                this._notifications$.next(notifications);
            }),
        );
    }

    closeNotifications(notificationIds: string[]): Observable<OracleResponse>
    {
        return this._http.post<any>('/api/closeNotifications',
            notificationIds
        );
    }

    initAudio()
    {
        this._audio = new Audio('../../../../assets/sounds/' + NOTIFICATION_SOUND_FILENAME);
        this._audio.load();
    }

    playNotificationAudio()
    {
        this._audio && this._audio.play();
    }

    private _getUnreadCount(notifications: Notification[]): number
    {
        return notifications.filter(notification => !notification.read).length;
    }
}
