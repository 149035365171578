import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DATE_FORMAT } from 'app/modules/share/global.constants';
import { OracleResponse } from 'app/modules/share/global.types';
import { HttpClientService } from 'app/modules/share/services/http-client.service';
import { Dictionary, keyBy, mapValues } from 'lodash';
import { DateTime } from 'luxon';
import { BehaviorSubject, Observable, Subject, map, tap } from 'rxjs';
import { EventDialogComponent } from './event-dialog/event-dialog.component';
import { ORACLE_DATE_TIME_FORMAT } from './project-constants';
import { EventDialogResponse, ProjectEvent } from './project.types';

@Injectable({
    providedIn: 'root'
})
export class CalendarService
{
    private _eventUpdated$ = new Subject<OracleResponse>();
    private _events$ = new BehaviorSubject<ProjectEvent[]>(null);
    private _eventsMap$ = new BehaviorSubject<Dictionary<ProjectEvent>>(null);

    get eventUpdated$(): Observable<OracleResponse>
    {
        return this._eventUpdated$.asObservable();
    }

    get events$(): Observable<ProjectEvent[]>
    {
        return this._events$.asObservable();
    }

    get eventsMap(): Dictionary<ProjectEvent>
    {
        return this._eventsMap$.getValue();
    }

    constructor(
        private _httpClient: HttpClientService,
        private _dialog: MatDialog,
    )
    {

    }

    getEvents(start: Date, end: Date): Observable<any>
    {
        return this.getCalendarEvent(start, end)
            .pipe(
                map((events: any[]) => events.map(event => {
                    return {
                        id: event.id,
                        title: event.description,
                        participants: event.participants,
                        start: this.toJsDate(event.startDate, event.startTime),
                        end: this.toJsDate(event.endDate, event.endTime),
                    }
                })),
                tap(events =>
                    {
                        console.log('events', events);

                        this._events$.next([...events]);
                        this._eventsMap$.next(
                            mapValues(keyBy(events, 'id'))
                        );
                    }
                )
            )
    }

    getCalendarEvent(start: Date, end: Date)
    {
        const dateStart = DateTime.fromJSDate(start).toFormat(DATE_FORMAT);
        const dateEnd = DateTime.fromJSDate(end).toFormat(DATE_FORMAT);

        return this._httpClient.get('/api/getCalendarEvent', { dateStart, dateEnd });
    }

    saveEvent(event: ProjectEvent): Observable<any>
    {
        const payload = {
            id: event.id,
            description: event.title,
            participants: event.participants || [],
            addNotification: event.emailNotification ? 'Y' : 'N',
            startDate: event.start.toISOString(),
            endDate: event.end && event.end.toISOString(),
            startTime: this._toTimeStr(event.start),
            endTime: event.end && this._toTimeStr(event.end),
        };

        //_eventUpdated$
        const observer = event.id ? this.updateEvent(payload) : this.createEvent(payload);
        return observer.pipe(
                tap(response => this._eventUpdated$.next(response)));
    }

    createEvent(payload: any): Observable<any>
    {
        return this._httpClient.post('/api/saveCalendarEvent', payload);
    }

    updateEvent(payload: any): Observable<any>
    {
        return this._httpClient.post('/api/updateCalendarEvent', payload);
    }

    deleteEvent(id: string): Observable<any>
    {
        return this._httpClient.post('/api/deleteCalendarEvent', { id });
    }

    openEventDialog(data: ProjectEvent): Observable<EventDialogResponse>
    {
        const dialogRef = this._dialog.open<EventDialogComponent, ProjectEvent, EventDialogResponse>(
            EventDialogComponent,
            {
                data,
                panelClass: [
                    'w-11/12',
                    'sm:w-3/5',
                ]
            }
        );
        return dialogRef.afterClosed();
    }

    toJsDate(date: string, time: string)
    {
        return this.toDateTime(date, time).toJSDate();
    }

    toDateTime(date: string, time: string)
    {
        return DateTime.fromFormat(date + ' ' + time, ORACLE_DATE_TIME_FORMAT);
    }

    private _toTimeStr(date: Date)
    {
        return DateTime.fromJSDate(date).toFormat('HH:mm:ss')
    }

}
