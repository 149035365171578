import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { combineLatest, of, switchMap } from 'rxjs';

export const NavigationGuard: CanActivateFn | CanActivateChildFn = (route, state) =>
{
    const navigationService = inject(NavigationService);
    const router: Router = inject(Router);

    return combineLatest([
        navigationService.get(),
        navigationService.availablePaths$,
    ]).pipe(
        switchMap(([_, paths]) =>
        {
            if( !isPathValid(paths, state.url) )
            {
                return of(router.parseUrl('/pages/error/403'));
            }
            // Allow the access
            return of(true);
        })
    );

    function isPathValid(paths: string[], url: string): boolean
    {
        return !!paths.find(path => url.startsWith(path));
    }
};
