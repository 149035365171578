import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { EmployeeBriefly } from '../global.types';
import { HttpClientService } from './http-client.service';
import { Dictionary, keyBy, mapValues } from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class EmployeesBrieflyService {

    private _employees$: BehaviorSubject<EmployeeBriefly[]> = new BehaviorSubject(null);
    private _employeesMap$: BehaviorSubject<Dictionary<EmployeeBriefly>> = new BehaviorSubject(null);

    get employees(): EmployeeBriefly[]
    {
        return this._employees$.getValue();
    }

    get employeesMap(): Dictionary<EmployeeBriefly>
    {
        return this._employeesMap$.getValue();
    }

    get employees$(): Observable<EmployeeBriefly[]>
    {
        return this._employees$.asObservable();
    }

    constructor(private _http: HttpClientService) {}

    getEmployeesBriefly(): Observable<EmployeeBriefly[]>
    {
        return this._http.get<EmployeeBriefly[]>('/api/getWorkers')
            .pipe(tap(workers => {
                this._employees$.next(workers);
                this._employeesMap$.next(
                    mapValues(keyBy(workers, 'id'))
                );
            }),
        );
    }

    getEmployeeById(id: string): EmployeeBriefly
    {
        const employees = this._employees$.getValue();
        return employees?.find(item => item.id == id);
    }
}
