import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { TranslocoModule } from '@ngneat/transloco';
import { REGEX_URL_PATTERN } from 'app/modules/share/global.constants';
import { IntegrationsService } from '../../integrations.service';
import { IntegrationSubType, MessengerType } from '../../integrations.types';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';
import { DialogTemplateComponent } from "../dialog-template/dialog-template.component";

@Component({
    selector: 'app-amio-dialog',
    standalone: true,
    templateUrl: './amio-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        TranslocoModule,
        FormsModule,
        ReactiveFormsModule,

        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatIconModule,

        DialogTemplateComponent
    ]
})
export class AmioDialogComponent extends BaseDialogComponent<AmioDialogComponent>
{
    get integrationSubType(): IntegrationSubType
    {
        return IntegrationSubType.amio;
    }
    messengerTypes = Object.values(MessengerType);

    constructor(
        private formBuilder: UntypedFormBuilder,
        private dialogRef: MatDialogRef<AmioDialogComponent>,
        private integrations: IntegrationsService,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ){
        super(dialogRef, integrations, data);

        this.form = this.formBuilder.group({
            name:
            [
                data?.name,
                Validators.required,
                this.nameUniqValidator.bind(this)
            ],
            apiKey:
            [
                data?.apiKey,
                Validators.required
            ],
            apiUrl:
            [
                data?.apiURL,
                [
                    Validators.required,
                    Validators.pattern(REGEX_URL_PATTERN)
                ]
            ],
            channel:
            [
                data?.channel,
                [
                    Validators.required,
                    Validators.pattern(/^[0-9]*$/)
                ]
            ],
            messengerType:
            [
                data?.messengerType || MessengerType.viber,
                Validators.required
            ],
        });
    }

}
