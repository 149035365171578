<div mat-dialog-title class="text-2xl font-medium">
    {{ 'eventReminder.title' | transloco }}
</div>

<div class="m-6">
    <div class="my-3">
        {{ data.title }}
    </div>
    <div class="my-3">
        {{ 'eventReminder.start' | transloco }} <span class="font-bold">{{ difference }}</span> {{ 'minutes' | transloco }}
    </div>
</div>

<div mat-dialog-actions align="end">
    <button mat-stroked-button (click)="onCancelClick()">{{ 'close' | transloco }}</button>
    <button
       *ngIf="!data.postponed"
        mat-stroked-button
        [color]="'primary'"
        [mat-dialog-close]="'postpone'"
    >
        {{ 'eventReminder.postponeOn' | transloco }} {{ postponeTime }} {{ 'minutes' | transloco }}
    </button>
</div>
