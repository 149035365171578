/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id      : 'dashboards',
        title   : 'Dashboards',
        subtitle: '',
        type    : 'group',
        icon    : 'heroicons_outline:home',
        children: [
            {
                id   : 'dashboards.project',
                title: 'Project',
                type : 'basic',
                icon : 'heroicons_outline:clipboard-document-check',
                link : '/dashboards/project',
            },
      //      {
      //          id   : 'dashboards.analytics',
      //          title: 'Analytics',
      //          type : 'basic',
     //           icon : 'heroicons_outline:chart-pie',
    //            link : '/dashboards/analytics',
    //        },

        ],
    },
    {
        id      : 'apps',
        title   : 'Applications',
        subtitle: '',
        type    : 'group',
        icon    : 'heroicons_outline:home',
        children: [

            {
                id   : 'apps.chat',
                title: 'Chat',
                type : 'basic',
                icon : 'heroicons_outline:chat-bubble-bottom-center-text',
                link : '/apps/chat',
                /* badge: {
                    title  : '2',
                    classes: 'px-2 bg-green-500 text-white rounded-full',
                }, */
            },
            {
                id   : 'apps.contacts',
                title: 'Contacts',
                type : 'basic',
                icon : 'heroicons_outline:user-group',
                link : '/apps/contacts',
            },

            {
                id   : 'apps.file-manager',
                title: 'File Manager',
                type : 'basic',
                icon : 'heroicons_outline:cloud',
                link : '/apps/file-manager',
            },
           {
               id      : 'apps.marketing',
               title   : 'Marketing',
               type    : 'collapsable',
               icon    : 'heroicons_outline:information-circle',
               link    : '/apps/marketing',
               children: [
                    {
                        id        : 'apps.marketing.viber',
                        title     : 'Viber',
                        type      : 'basic',
                        link      : '/apps/marketing/viber-newsletter',
                        exactMatch: true,
                    },
                    {
                        id        : 'apps.marketing.email',
                        title     : 'Emailing',
                        type      : 'basic',
                        link      : '/apps/marketing/email-newsletter',
                        exactMatch: true,
                    }],
		},
  //                  {
  //                      id   : 'apps.help-center.faqs',
  //                      title: 'FAQs',
  //                      type : 'basic',
  //                      link : '/apps/help-center/faqs',
  //                  },
  //                  {
  //                      id   : 'apps.help-center.guides',
  //                      title: 'Guides',
  //                      type : 'basic',
   //                     link : '/apps/help-center/guides',
     //               },
  //                  {
   //                     id   : 'apps.help-center.support',
  //                      title: 'Support',
   //                     type : 'basic',
  //                      link : '/apps/help-center/support',
   //                 },
  //              ],
   //         },
            {
                id   : 'apps.mailbox',
                title: 'Mailbox',
                type : 'basic',
                icon : 'heroicons_outline:envelope',
                link : '/apps/mailbox',
                badge: {
                    title  : '27',
                    classes: 'px-2 bg-pink-600 text-white rounded-full',
                },
            },
            {
                id   : 'apps.notes',
                title: 'Notes',
                type : 'basic',
                icon : 'heroicons_outline:pencil-square',
                link : '/apps/notes',
            },
            {
                id   : 'apps.scrumboard',
                title: 'Scrumboard',
                type : 'basic',
                icon : 'heroicons_outline:view-columns',
                link : '/apps/scrumboard',
            },
            {
                id   : 'apps.tasks',
                title: 'Tasks',
                type : 'basic',
                icon : 'heroicons_outline:check-circle',
                link : '/apps/tasks',
            },
            {
                id   : 'apps.integrations.settings',
                title: 'forDevelopers',
                type : 'collapsable',
                icon : 'heroicons_outline:wrench-screwdriver',
                link : '/apps/developers',
                children: [
                    {
                        id        : 'apps.marketing.viber',
                        title     : 'integrations.title',
                        type      : 'basic',
                        link      : '/apps/developers/integrations',
                        exactMatch: true,
                    },
                ]
            }
        ],
    },






];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id      : 'dashboards',
        title   : 'Dashboards',
        tooltip : 'Dashboards',
        type    : 'aside',
        icon    : 'heroicons_outline:home',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'apps',
        title   : 'Apps',
        tooltip : 'Apps',
        type    : 'aside',
        icon    : 'heroicons_outline:qrcode',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },


];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id      : 'dashboards',
        title   : 'DASHBOARDS',
        type    : 'group',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'apps',
        title   : 'APPS',
        type    : 'group',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id   : 'others',
        title: 'OTHERS',
        type : 'group',
    },

];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id      : 'dashboards',
        title   : 'Dashboards',
        type    : 'group',
        icon    : 'heroicons_outline:home',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'apps',
        title   : 'Apps',
        type    : 'group',
        icon    : 'heroicons_outline:qrcode',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },

];
