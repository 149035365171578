import { Injectable } from '@angular/core';
import { HttpClientService } from 'app/modules/share/services/http-client.service';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { CompanyInfo } from './company.types';
import { OracleResponse } from 'app/modules/share/global.types';

@Injectable({providedIn: 'root'})
export class CompanyService
{
    private _companyInfo$ = new BehaviorSubject<CompanyInfo>(null);

    get companyInfo$(): Observable<CompanyInfo>
    {
        return this._companyInfo$.asObservable();
    }

    get companyInfo(): CompanyInfo
    {
        return this._companyInfo$.getValue();
    }

    constructor(private _http: HttpClientService) { }

    loadCompanyInfo()
    {
        return this._http.get<CompanyInfo>('/api/GetCompanyInfo')
            .pipe(
                tap(companyInfo => this._companyInfo$.next(companyInfo))
            );
    }

    saveCompanyInfo(companyPayload: any): Observable<OracleResponse>
    {
        return this._http.post<OracleResponse>('/api/ChangeCompanyInfo', companyPayload);
    }

}
