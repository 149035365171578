<button
    class="mb-4"
    mat-stroked-button
    color="primary"
    (click)="add()"
>
    <div class="flex items-center">
        <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:plus-circle'"></mat-icon>
        <span class="ml-2 text-md font-medium">
            {{ 'integrations.addManager' | transloco }}
        </span>
    </div>

</button>

<form [formGroup]="queueForm" cdkDropList (cdkDropListDropped)="drop($event)">

    <div class="grid grid-cols-6 sm:grid-cols-12 gap-2">
        <div class="col-span-4 sm:col-span-10">
            <mat-label>
                {{ 'manager' | transloco }}
            </mat-label>
        </div>
        <div class="col-span-2">
            <mat-label>
                {{ 'integrations.interval' | transloco }}
            </mat-label>
        </div>
    </div>

    <div
        cdkDrag
        class="grid grid-cols-6 sm:grid-cols-12 gap-2 my-2"
        *ngFor="let control of queueFormArray?.controls; index as idx; let first = first;"
        [formGroup]="control"
    >
        <mat-form-field class="col-span-4 sm:col-span-10" subscriptSizing="dynamic">
            <mat-select
                formControlName="userId"
                [compareWith]="compareManagers"
            >
                <mat-option *ngFor="let manager of (managers$ | async)" [value]="manager.id">
                    {{ manager.fullName }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="" subscriptSizing="dynamic">
            <input matInput formControlName="interval">
        </mat-form-field>

        <button class="ml-2 mt-3" (click)="remove(idx)">
            <mat-icon color="primary">delete</mat-icon>
        </button>

    </div>

    <mat-form-field class="w-full mt-2" subscriptSizing="dynamic">
        <mat-label>
            {{ 'integrations.autoAnswer' | transloco }}
        </mat-label>
        <input matInput formControlName="autoAnswer">
    </mat-form-field>

    <mat-slide-toggle formControlName="createTask" class="w-full mt-4" color="primary">
        {{ 'integrations.createTask' | transloco }}
    </mat-slide-toggle>

</form>

